import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const CyberEngineering = () => {
  return (
    <>
      <Helmet>
        <title>Cylogics.com | Cyber Engineering</title>
        <meta
          name="description"
          content="We specialize in Cloud Architecture, Cloud Services, and System Integration for migration experience. We support from planning & execution to post-migration."
        />
        <meta
          name="keywords"
          content="Cloud Migration Project, Cloud Architecture, Cloud Services, cloud migration, System Integration"
        />
      </Helmet>
    </>
  );
};

export default CyberEngineering;
