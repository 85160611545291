import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PageBorder = styled.div`
  margin-top: 110px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  border: 4px double #000;
  border-radius: 10px;
`;
const Container = styled.div`
  margin-top: 20px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  /* font: normal normal medium 50px/40px Manrope; */
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  font-family: Manrope;
  padding: 10px;
  color: #000000;
  line-height: 200%;
  text-align: justify;
`;

const Title = styled.h3`
  color: var(--e-global-color-f57c355);
  font-family: 'Poppins', Sans-serif;
  font-size: 35px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
`;

const saas = () => {
  return (
    <Wrapper>
      <PageBorder>
        <Container>
          <Title>What is SaaS (Software as a Service)?</Title>
          <Paragraph>
            SaaS stands for Software as a Service. It's a cloud computing model
            where software applications are hosted and provided to users over
            the internet on a subscription basis. In this model, users access
            the software through a web browser or a client application without
            needing to install or manage the application locally on their
            devices.
          </Paragraph>
          <Paragraph>
            With SaaS, software vendors host and maintain the applications on
            their servers, handling tasks such as software updates, maintenance,
            security, and data backups. Users pay for SaaS applications
            typically on a subscription or pay-as-you-go basis, often based on
            usage or the number of users accessing the software.
          </Paragraph>
          <Paragraph>
            SaaS applications cover a wide range of functionalities and
            industries, including office productivity tools (like Google
            Workspace or Microsoft Office 365), customer relationship management
            (CRM) software (such as Salesforce), project management tools (like
            Asana or Trello), and many other business applications.
          </Paragraph>
          <Paragraph>
            This model offers several advantages, including accessibility from
            any internet-connected device, automatic updates to the latest
            software versions, scalability to accommodate growing business
            needs, and reduced upfront costs as there's no need for purchasing
            and maintaining on-premises hardware or software. SaaS has become
            increasingly popular due to its convenience, cost-effectiveness, and
            ease of use for businesses of all sizes.
          </Paragraph>
        </Container>
      </PageBorder>
    </Wrapper>
  );
};

export default saas;
