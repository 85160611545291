import React, {useState, useEffect} from 'react';
import styled, {keyframes} from 'styled-components';
import CS01 from '../assets/img/cs01.webp';

const BoxWrapper = styled.div`
  max-width: 1080px;
  height: 600px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const Left = styled.div`
  width: 40%;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const Title = styled.h2`
  text-align: left;
  font-size: 2.5rem;
`;
const Paragraph = styled.p`
  padding-top: 20px;
  padding-bottom: 10px;
`;
const ExploreButton = styled.button`
  display: inline-block;
  padding: 7px 28px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #e50075;
  background: transparent;
  border: 3px solid #e50075;
  border-radius: 30px;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  font-size: 16px;
  text-decoration: none;
  :hover {
    background: #e50075;
    color: #fff;
  }
`;
const Right = styled.div`
  width: 60%;
  height: 500px;
`;
const BoxContainer = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  margin: 50px;
  /* overflow: hidden; */
`;

const animateTop = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const animateRight = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const animateBottom = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const BoxImageL = styled.img`
  position: absolute;
  width: 400px;
  height: 300px;
`;
const BoxImageP = styled.img`
  position: absolute;
  width: 300px;
  height: 440px;
`;

const TopImage = styled(BoxImageP)`
  animation: ${animateTop} 1s ease-in-out;
`;

const RightImage = styled(BoxImageL)`
  animation: ${animateRight} 1s ease-in-out;
`;

const BottomImage = styled(BoxImageL)`
  animation: ${animateBottom} 1s ease-in-out;
`;

const BoxImageAnimator = () => {
  // State to manage loading of images
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Simulating image loading delay
    const delay = setTimeout(() => {
      setLoaded(true);
    }, 300);

    return () => clearTimeout(delay);
  }, []);

  return (
    <BoxWrapper>
      <Left>
        <Title>Case Studies</Title>
        <Paragraph>
          Discover real success stories with our managed IT services, cloud
          architecture, and IT security solutions. Dive into concise case
          studies showcasing how our expertise has transformed businesses,
          ensuring seamless operations, fortified security, and optimized
          performance. Witness firsthand how our tailored solutions have
          empowered organizations to thrive in the ever-evolving digital
          landscape.
        </Paragraph>
        <a href="/casestudies">
        <ExploreButton>Explore</ExploreButton>
        </a>
      </Left>
      <Right>
        <BoxContainer>
          {loaded && (
            <>
              <TopImage
                src="https://linkhumans.com/wp-content/uploads/2022/04/loreal-paris.jpg"
                alt="Top Image"
                style={{top: '0%', left: '0%'}}
              />
              <RightImage
                src="https://linkhumans.com/wp-content/uploads/2022/05/service-dos.jpg"
                alt="Right Image"
                style={{top: '10%', left: '25%'}}
              />
              <BottomImage
                src={CS01}
                alt="Bottom Image"
                style={{top: '55%', left: '10%'}}
              />
            </>
          )}
        </BoxContainer>
      </Right>
    </BoxWrapper>
  );
};
export default BoxImageAnimator;
