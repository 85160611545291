import React from 'react';
import styled from 'styled-components';

// Styled component for Contact Button
const ContactButton = styled.div`
  position: fixed;
  font-size: 16px;
  z-index: 100;
  width: 165px;
  height: 50px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  background-color: #276083;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 15px 40px;
  font-size: 17px;
  /* transition: all 0.2s; */
  top: 60%;
  bottom: 0%;
  right: -60px;
  transform: rotate(-90deg);
  /* box-shadow: 0 -1px 5px 0.5px rgba(0, 8, 34, 0.2); */
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

// Your other components...

// Example of a main component containing the "Contact" button
const MainComponent = () => {
  // ...Your component logic

  return (
    <div>
      <a href="/contact">
        <ContactButton>Contact Us</ContactButton>
      </a>
    </div>
  );
};

export default MainComponent;
