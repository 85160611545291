import React from 'react'
import {Helmet} from 'react-helmet';
import CaseStudiesC from '../components/CaseStudiesC'
import Banner from '../components/Banner';
import BGI from '../assets/img/banners/case_studies.png';

const CaseStudies = () => {
  return (
    <>
     <Helmet>
        <title>Cloud Migration - Case Studies</title>
        <meta
          name="description"
          content="Maximize business potential with our advanced cloud solutions. We provides scalable, secure, and reliable cloud solutions tailored to your unique needs."
        />
        <meta
          name="keywords"
          content="Cloud solutions, Cloud Design, Cloud Migration Project"
        />
      </Helmet>
      <Banner image={BGI}/>
    <CaseStudiesC />
    </>
  )
}

export default CaseStudies