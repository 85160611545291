import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PageBorder = styled.div`
  margin-top: 110px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  border: 4px double #000;
  border-radius: 10px;
`;
const Container = styled.div`
  margin-top: 20px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  /* font: normal normal medium 50px/40px Manrope; */
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  font-family: Manrope;
  padding: 10px;
  color: #000000;
  line-height: 200%;
  text-align: justify;
`;

const Title = styled.h3`
  color: var(--e-global-color-f57c355);
  font-family: 'Poppins', Sans-serif;
  font-size: 35px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
`;

const Containerize = () => {
  return (
    <Wrapper>
      <PageBorder>
        <Container>
          <Title>What is Container?</Title>
          <Paragraph>
            A container is a standardized unit of software that packages an
            application along with its dependencies, libraries, and
            configurations, enabling it to run consistently and reliably across
            different computing environments.
          </Paragraph>
          <Paragraph>
            Containers provide a lightweight, portable, and isolated environment
            for applications. They encapsulate everything an application needs
            to run, including the code, runtime, system tools, system libraries,
            and settings. This encapsulation ensures that the application runs
            uniformly regardless of the infrastructure or platform on which it
            is deployed.
          </Paragraph>
          <Paragraph>
            One of the key technologies used in containers is Docker, which
            popularized the concept of containerization. Docker allows
            developers to create, deploy, and manage containers efficiently.
            Containers are designed to be portable, making it easy to move
            applications between different environments, such as development,
            testing, staging, and production, without any changes in behavior.
          </Paragraph>
          <Paragraph>
            Containers offer several advantages, including faster application
            deployment, improved scalability, increased consistency across
            environments, efficient resource utilization, and simplified
            application management. They have become a fundamental technology in
            modern software development and deployment, enabling organizations
            to build and deploy applications more efficiently and consistently.
          </Paragraph>
        </Container>
      </PageBorder>
    </Wrapper>
  );
};

export default Containerize;
