import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Banner from "../components/Banner";
import BGI from "../assets/img/banners/devopsback.jpg";
import ContactComponent from "../components/ContactComponent";
// import {Card} from 'react-bootstrap';
const Wrapper = styled.div`
  width: 90%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
`;
const Container = styled.div`
  /* background: #cfcfcf; */
  max-width: 100%;
`;

const ServiceContainer = styled.div`
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  align-content: space-between;
  text-align: left;
  padding-top: 2%;
  padding-bottom: 2%;
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  /* color: #444; */
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 30px;
`;

const Yus = styled.div`
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
const YusTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 10px;
`;

const DevOps = () => {
  return (
    <>
      <Helmet>
        <title>Best Practices for a Successful Cloud Migration Project</title>
        <meta
          name="description"
          content="We specialize in Cloud Architecture, Cloud Services, and System Integration for migration experience. We support from planning & execution to post-migration."
        />
        <meta
          name="keywords"
          content="Cloud Migration Project, Cloud Architecture, Cloud Services, cloud migration, System Integration"
        />
      </Helmet>
      <Banner image={BGI} title={"DevOPS & DevSecOPS"} subtitle={""} />
      <Wrapper>
        <Container style={{ background: "white" }}>
          <ServiceContainer>
            <Title>DevOps Consulting </Title>
            <Paragraph>
              "Unlock the true potential of your business operations with our
              specialized DevOps consulting services. In today's rapidly
              evolving technological landscape, staying competitive demands
              efficiency, agility, and continuous innovation. That's where our
              expert DevOps consultants come in."
            </Paragraph>
          </ServiceContainer>
        </Container>
        <Container>
          <ServiceContainer>
            <Title>What is DevOps?</Title>
            <Paragraph>
              DevOps is more than just a methodology; it's a cultural shift that
              merges development (Dev) and operations (Ops) to streamline
              software development, deployment, and management processes. By
              fostering collaboration, automation, and continuous
              integration/continuous deployment (CI/CD), DevOps empowers
              businesses to deliver high-quality software faster and more
              reliably.
            </Paragraph>
          </ServiceContainer>
        </Container>

        <Container style={{ background: "white" }}>
          <ServiceContainer>
            <Title>Our DevOps Consulting Services</Title>
            <Paragraph>
              At Cylogics, we offer tailored DevOps solutions designed to
              address your unique business challenges. Our experienced
              consultants work closely with your team to:
            </Paragraph>
            <Yus>
              <YusTitle>Assessment and Strategy Development</YusTitle>
              <Paragraph>
                We conduct a comprehensive analysis of your current
                infrastructure, workflows, and technology stack. Based on this
                assessment, we develop a customized DevOps strategy aligned with
                your business objectives.
              </Paragraph>
            </Yus>
            <Yus>
              <YusTitle>Implementation and Automation</YusTitle>
              <Paragraph>
                Leveraging industry best practices, we assist in implementing
                DevOps tools and practices to automate workflows, enhance
                collaboration, and improve efficiency across your organization.
              </Paragraph>
            </Yus>
            <Yus>
              <YusTitle>
                Continuous Integration/Continuous Deployment (CI/CD)
              </YusTitle>
              <Paragraph>
                Our experts optimize your software delivery pipeline, enabling
                rapid and reliable releases while ensuring code quality,
                testing, and deployment automation.
              </Paragraph>
            </Yus>
            <Yus>
              <YusTitle>Cloud Migration and Optimization</YusTitle>
              <Paragraph>
                We help you migrate to the cloud seamlessly, leveraging its
                scalability, security, and cost-efficiency. Additionally, we
                optimize your cloud infrastructure for peak performance and
                cost-effectiveness.
              </Paragraph>
            </Yus>
            <Yus>
              <YusTitle>Monitoring and Optimization</YusTitle>
              <Paragraph>
                Continuous monitoring and performance optimization are vital for
                sustained success. We set up monitoring tools and processes to
                detect issues early, ensuring optimal performance and minimal
                downtime.
              </Paragraph>
            </Yus>
          </ServiceContainer>
        </Container>
      </Wrapper>
      <ContactComponent />
    </>
  );
};

export default DevOps;
