// Layout1.js
import React from 'react';
import {Outlet} from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ContactButton from '../components/ContactButton';

const Layout1 = () => {
  return (
    <div className="layout1-container">
      <Navbar />
      <main>
        <ContactButton />
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default Layout1;
