export const MenuItems = [
  {
    title: 'Cloud Services',
    path: '/cloud',
    cName: 'dropdow-link',
  },
  {
    title: 'Cyber Security',
    path: '/security',
    cName: 'dropdow-link',
  },
  {
    title: 'IT Compliance',
    path: '/compliance',
    cName: 'dropdow-link',
  },
  {
    title: 'DevOps Consulting',
    path: '/devops',
    cName: 'dropdow-link',
  },
  {
    title: 'IT Consulting',
    path: '/consulting',
    cName: 'dropdow-link',
  },
];
