import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Banner from "../components/Banner";
import BGI from "../assets/img/banners/security.png";
import ContactComponent from "../components/ContactComponent";
// import {Card} from 'react-bootstrap';
const Wrapper = styled.div`
  width: 90%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
`;
const Container = styled.div`
  background: #cfcfcf;
  max-width: 100%;
`;

const ServiceContainer = styled.div`
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  align-content: space-between;
  text-align: left;
  padding-top: 2%;
  padding-bottom: 2%;
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  color: #444;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 30px;
`;

const Yus = styled.div`
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
const YusTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 10px;
`;

const Compliance = () => {
  return (
    <>
      <Helmet>
        <title>Best Practices for a Successful Cloud Migration Project</title>
        <meta
          name="description"
          content="We specialize in Cloud Architecture, Cloud Services, and System Integration for migration experience. We support from planning & execution to post-migration."
        />
        <meta
          name="keywords"
          content="Cloud Migration Project, Cloud Architecture, Cloud Services, cloud migration, System Integration"
        />
      </Helmet>
      <Banner image={BGI} title={"Audit & Compliance"} subtitle={""} />
      <Wrapper>
        <Container style={{ background: "white" }}>
          <ServiceContainer>
            <Title>Comprehensive IT Compliance Consulting Solutions </Title>
            <Paragraph>
              At Cylogics, we specialize in guiding businesses through the
              intricate landscape of IT compliance. Our seasoned consultants
              offer tailored strategies to achieve ISO 27001, MAS-TRM, PCI-DSS,
              SOC2, GDPR, and more. We prioritize seamless IT process
              implementation and Quality Management Systems (QMS) to fortify
              your IT department.
            </Paragraph>
          </ServiceContainer>
        </Container>

        <Container style={{ background: "white" }}>
          <ServiceContainer>
            <Title>Our Offerings.</Title>
            <Paragraph>
              We offer enablement of most of the Compliance Standards. Partner
              with Cylogics for comprehensive IT compliance solutions. Our
              experts navigate complexities, ensuring your business meets
              regulatory requirements while fostering a secure and compliant IT
              environment.
            </Paragraph>
            <Yus>
              <YusTitle>ISO 27001 Compliance</YusTitle>
              <Paragraph>
                Assisting in the establishment and maintenance of an Information
                Security Management System (ISMS) aligned with ISO 27001
                standards.
              </Paragraph>
            </Yus>
            <Yus>
              <YusTitle>
                {" "}
                Technology Risk Management Guidelines (Monitory Authority of
                Singapore)
              </YusTitle>
              <Paragraph>
                Expert guidance to meet the Monetary Authority of Singapore's
                Technology Risk Management guidelines, ensuring robust
                cybersecurity measures.
              </Paragraph>
            </Yus>
            <Yus>
              <YusTitle> PCI-DSS Compliance</YusTitle>
              <Paragraph>
                Comprehensive support for Payment Card Industry Data Security
                Standard (PCI-DSS) compliance, safeguarding sensitive payment
                card data.
              </Paragraph>
            </Yus>
            <Yus>
              <YusTitle>SOC2 Compliance</YusTitle>
              <Paragraph>
                Assistance in meeting the Service Organization Control (SOC2)
                requirements, validating trust and transparency in your
                services.
              </Paragraph>
            </Yus>
            <Yus>
              <YusTitle>GDPR</YusTitle>
              <Paragraph>
                Guidance for General Data Protection Regulation (GDPR)
                adherence, ensuring data protection and privacy compliance for
                EU citizens.
              </Paragraph>
            </Yus>
            <Yus>
              <YusTitle>IT Process Implementation</YusTitle>
              <Paragraph>
                Tailored solutions for streamlining IT processes, enhancing
                operational efficiency, and minimizing risk.
              </Paragraph>
            </Yus>
            <Yus>
              <YusTitle>Quality Management Systems</YusTitle>
              <Paragraph>
                Quality Management Systems (QMS) for IT Departments:
                Implementing robust QMS frameworks to ensure consistent service
                delivery and operational excellence.
              </Paragraph>
            </Yus>
          </ServiceContainer>
        </Container>
      </Wrapper>
      <ContactComponent />
    </>
  );
};

export default Compliance;
