import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 93vh;
  position: relative;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 0px;
    overflow: hidden;
    position: unset;
  }
`;

const BgVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;

  overflow-x: hidden;

  @media screen and (max-width: 768px) {
  }
`;

const Overlay = styled.div`
  width: 100%;
  position: absolute;
  object-fit: cover;
  background-color: rgba(50, 50, 50, 0.5);
`;

const BgContent = styled.div`
  position: absolute;
  width: 100%;
  height: 50%;
  top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  @media screen and (max-width: 768px) {
    top: 50px;
    width: auto;
  }
`;

const Title = styled.p`
  font: normal normal 800 46px/63px Manrope;
  @media screen and (max-width: 768px) {
    font: normal normal 800 23px/30px Manrope;
    justify-content: center;
  }
`;

const SubHead = styled.p`
  margin-top: 20px;
  width: 50%;
  font: normal normal 600 24px/33px Manrope;
  justify-content: center;
  text-align: center;
  @media screen and (max-width: 768px) {
    font: normal normal normal 20px/30px Manrope;
    width: 80%;
  }
`;
const BannerBG = (props) => {
  return (
    <Wrapper>
      <Overlay />
      <BgVideo src={props.bg} autoPlay muted loop />
      <BgContent>
        <Title>Securing Your Tomorrow, Today.</Title>
        <SubHead>
          "Empowering enterprises with secure, tailored solutions."
        </SubHead>
      </BgContent>
    </Wrapper>
  );
};

export default BannerBG;
