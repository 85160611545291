import React from 'react';
import {Helmet} from 'react-helmet';
import ContactComponent from '../components/ContactComponent';

const ContactUs = () => {
  return (
    <>
      <Helmet>
        <title>Mastering Cloud Migrations</title>
        <meta
          name="description"
          content="Our team can help you navigate the complexities of cloud migrations and maximize the benefits of this game-changing technology."
        />
        <meta
          name="keywords"
          content="Cloud Migrations, Managed Cloud Services, Cloud Architecture,  Amazon Cloud, Google Cloud"
        />
      </Helmet>
      <ContactComponent />
      {/* <Wrapper>
        <Left>
          <Image src={BGI} />
          <Title>Get in Touch with US</Title>
          <Paragraph>Lets discuss on your project.</Paragraph>
        </Left>
        <Right>
          <ContactForm />
        </Right>
      </Wrapper> */}
    </>
  );
};

export default ContactUs;
