import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Banner from "../components/Banner";
import BGI from "../assets/img/banners/case_studies.png";
import "./pp.css";

const Wrapper = styled.div`
  width: 90%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
`;

const FintechEKS = () => {
  return (
    <>
      <div className="Container">
        <Helmet>
          <title>AWS EKS - Case Studies</title>
          <meta
            name="description"
            content="Maximize business potential with our advanced cloud solutions. We provides scalable, secure, and reliable cloud solutions tailored to your unique needs."
          />
          <meta
            name="keywords"
            content="Cloud solutions, Cloud Design, Cloud Migration Project"
          />
        </Helmet>
        {/* <Banner/> */}
        <br />
        <Wrapper>
          <h1>AWS EKS Architecture for Financial Application</h1>
          <h2>Client Overview:</h2>
          <p>
            In today’s rapidly evolving financial sector, ensuring reliable,
            scalable, and secure application architecture is paramount. Client
            addresses these needs by deploying a robust cloud-based financial
            application architecture on AWS. This case study explores the core
            components and design principles of Client's architecture, focusing
            on reliability, security, and scalability.
          </p>
          <h2>Key Pillars of the Architecture:</h2>
          <p>
            CYLOGICS proposed a comprehensive cloud migration strategy, moving
            the client's infrastructure to AWS Cloud. Here’s how we transformed
            their IT environment:
            <br />
            <ol>
              <li>Clustered Isolation:</li>
              <p>
                The architecture is divided into dedicated clusters for
                different functionalities: frontend processing, core backend
                operations, and key management. This segregation enhances
                security by isolating critical processes and minimizes the risk
                of cross-contamination in case of a security breach.
              </p>
              <li>Dynamic Scalability:</li>
              <p>
                Using AWS Auto Scaling features, Client can automatically adjust
                resources in response to demand fluctuations, ensuring high
                performance and uptime while optimizing costs.
              </p>
              <li>Fortress-Grade Security:</li>
              <p>
                Security is a top priority, with Hardware Security Modules
                (HSMs) integrated to provide hardware-level data encryption and
                protection, making the architecture resilient against potential
                cyber threats.
              </p>
              <li>Centralized Intelligence:</li>
              <p>
                AWS CloudWatch is utilized for monitoring and observability
                across all AWS resources, allowing for real-time performance
                insights and streamlined troubleshooting.
              </p>
              <li>AWS Security Hub:</li>
              <p>
                Security Hub plays a critical role in maintaining a robust
                security posture by continuously monitoring AWS resources for
                compliance and security best practices, enabling proactive
                threat detection and response.
              </p>
            </ol>
          </p>
          {/* <ol>
          <h2>System Architecture and Key Components:</h2>
          <li>
            {" "}
            Frontend Cluster: Deployed using AWS Elastic Kubernetes Service
            (EKS), this cluster manages web traffic through an Application Load
            Balancer (ALB) and utilizes Auto Scaling Groups to handle
            fluctuating demands.
          </li>
          <li>
            Core Cluster: Dedicated to backend processing, this cluster
            integrates with Cloud HSMs for secure handling of sensitive
            operations. It also leverages ALBs and Auto Scaling Groups for
            optimal performance.
          </li>
          <li>
            Key Cluster: This cluster is specifically designed for encryption
            key management, maintaining stringent security protocols and
            utilizing ALBs and Auto Scaling for scalability.{" "}
          </li>
          <li>
            Database: The application uses Amazon RDS with MySQL for reliable
            and scalable database management, ensuring data consistency and
            availability.
          </li>
          <li>
            Security and Compliance: The architecture implements AWS Identity
            and Access Management (IAM) to enforce least privilege access
            controls, restricting resource access based on user roles and
            responsibilities. This approach minimizes security risks and ensures
            compliance with best practices.
          </li>
          <li>
            Load Balancers and API Management: The system includes three types
            of load balancers: External ALB for public access, Network Load
            Balancer (NLB) for API traffic, and Internal ALB for secure
            communication between internal components. AWS API Gateway manages
            the API endpoints, providing a secure interface for external and
            internal interactions.
          </li>
          <li>
            Data Storage and Access: AWS S3 buckets are used for secure file
            storage, with access restricted to core application clusters and
            privileged internal users. BeyondTrust further secures access to
            critical storage and database resources.
          </li>
          <li>
            VPN for Privileged Access: VPN endpoints are established for secure
            access to the VPC and other internal resources, restricted to
            authorized internal users for administrative and management
            purposes.
          </li>
        </ol> */}
          <ul>
            <h2>Benefits and Outcomes:</h2>
            <li>
              Enhanced Security: With HSMs, IAM, and AWS Security Hub, Client's
              architecture ensures a high level of security, protecting
              sensitive financial data from potential threats.
            </li>
            <li>
              Scalability and Performance: The use of Auto Scaling and
              Kubernetes clusters allows Client to handle high traffic volumes
              seamlessly, ensuring consistent performance and availability.
            </li>
            <li>
              Cost Efficiency: The architecture’s dynamic scalability ensures
              that resources are used optimally, reducing costs by scaling down
              during low demand periods.
            </li>
          </ul>
           <h2>Conclusion</h2>
          <p>
            Client's AWS-based solution architecture exemplifies a commitment to
            robust, scalable, and secure financial application deployment. By
            leveraging AWS services like EKS, RDS, HSMs, and Security Hub,
            Client achieves a secure, high-performance, and cost-effective
            infrastructure capable of adapting to the evolving demands of the
            financial industry.
          </p>
        </Wrapper>
      </div>
    </>
  );
};

export default FintechEKS;
