import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 50px;
  background-color: #54173b;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: white;
  @media screen and (max-width: 768px) {
  }
`;

const BenfitHeads = styled.div`
  font: normal normal medium 14px/19px Manrope;
  @media screen and (max-width: 768px) {
    font: normal normal normal 10px Manrope;
  }
`;

const Benifits = () => {
  return (
    <Container>
      <BenfitHeads>Scalability</BenfitHeads>
      <BenfitHeads>Accessibility</BenfitHeads>
      <BenfitHeads>Security</BenfitHeads>
      <BenfitHeads>Availability</BenfitHeads>
      <BenfitHeads>Cost Efficient</BenfitHeads>
    </Container>
  );
};

export default Benifits;
