import React from 'react';
import styled from 'styled-components';
import Banner from '../components/Banner';
import BGI from '../assets/img/banners/servicebanner.png';
import Dock from '../assets/img/container.jpg';

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

const Paragraph = styled.p`
  /* font: normal normal medium 50px/40px Manrope; */
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  text-align: justify;
  font-family: Manrope;
  color: #000000;
  line-height: 200%;
`;

const Title = styled.h3`
  color: var(--e-global-color-f57c355);
  font-family: 'Poppins', Sans-serif;
  font-size: 35px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
`;
const SubTitle = styled.h4`
  color: var(--e-global-color-f57c355);
  font-family: 'Poppins', Sans-serif;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
`;
const DockerIMG = styled.img`
  width: 70%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const WhatWeDo = () => {
  return (
    <Container>
      <Title>What We DO!!!</Title>
      <Banner image={BGI} />
      <Paragraph>
        Comprehensive Solutions: Cylogics offers holistic enterprise solutions,
        including Infrastructure Architecture, Cloud Services, and Cyber
        Security.
      </Paragraph>
      <Paragraph>
        Diverse Clientele: Our services cater to SMEs, MNCs, corporate entities,
        and government sectors.
      </Paragraph>
      <Paragraph>
        Unwavering Commitment: We are dedicated to delivering top-notch
        solutions and support to our esteemed customers.
      </Paragraph>

      <SubTitle>IT Consulting</SubTitle>
      <Paragraph>
        Expert guidance and solutions to optimize your IT infrastructure and
        strategy, driving efficiency and innovation for your business.
      </Paragraph>
      <SubTitle>Managed Services</SubTitle>
      <Paragraph>
        Proactive management and support for your IT environment, ensuring
        uninterrupted operations and peace of mind. Managed cloud services are
        services that offer partial or complete management of a client's cloud
        resources or infrastructure. Management responsibilities can include
        migration, configuration, optimization, security, and maintenance.
      </Paragraph>
      <SubTitle>IT Compliance</SubTitle>
      <Paragraph>
        Ensuring regulatory adherence and data security through comprehensive IT
        compliance solutions
      </Paragraph>
      <SubTitle>Cloud Migration</SubTitle>
      <Paragraph>
        Seamless transition to the cloud, unlocking scalability and agility for
        your digital transformation journey. Whether public or private, if you
        are re-platforming, re-hosting, refactoring, or hybrid – for any cloud
        platform you choose – Cylogics ensures success through every step of
        your migration journey.
      </Paragraph>
      <SubTitle>Cloud Architecture</SubTitle>
      <Paragraph>
        Cloud architecture is the way technology components combine to build a
        cloud, in which resources are pooled through virtualization technology
        and shared across a network. The components of a cloud architecture
        include: A front-end platform (the client or device used to access the
        cloud)
      </Paragraph>
      <SubTitle>Cloud optimization</SubTitle>

      <Paragraph>
        Cloud cost optimization is the process of reducing your overall cloud
        spend by identifying mismanaged resources, eliminating waste, reserving
        capacity for higher discounts, and Right Sizing computing services to
        scale. The cloud offers organizations unlimited scalability and lower IT
        costs by only charging for the resources you use. But the truth about
        Amazon Web Services (AWS) pricing and Microsoft Azure pricing is that
        cloud customers pay for the resources they order, whether they use them
        or not. In their recent report, How to Identify Solutions for Managing
        Costs in Public Cloud IaaS, Gartner analysts Brandon Medford and Craig
        Lowery estimate that as much as 70% of cloud costs are wasted.
      </Paragraph>
      <SubTitle>Cloud VPN</SubTitle>
      <Paragraph>
        What is a cloud VPN? Image result for cloud vpn Cloud VPN securely
        connects your peer network to your Virtual Private Cloud (VPC) network
        through an IPsec VPN connection. Traffic traveling between the two
        networks is encrypted by one VPN gateway and then decrypted by the other
        VPN gateway. This action protects your data as it travels over the
        internet.
      </Paragraph>
      <SubTitle>Containerization in cloud computing</SubTitle>
      <Paragraph>
        A Container in cloud computing is an approach to operating system
        virtualization. By this, the user can work with a program and its
        dependencies using resource procedures that are isolated. The code of
        the application can be bundled with configurations and dependencies in a
        systematic manner.
      </Paragraph>
      <DockerIMG src={Dock} />

      <SubTitle>Virtual CTO / CIO</SubTitle>
      <Paragraph>
        With our experienced technology or information management professional
        experience we provide advisory and strategic services to your company on
        a part-time, remote, or contractual basis. This arrangement allows
        organizations to access high-level expertise without the need to hire a
        full-time executive.
      </Paragraph>
      <ol style={{textAlign: 'left'}}>
        <li>
          <strong>Technology Strategy:</strong> We help define and align the
          company's technology goals with its overall business objectives. This
          involves evaluating existing technology systems, recommending
          improvements, and identifying new opportunities for innovation.
        </li>
        <li>
          <strong>IT Infrastructure: </strong>We assess the company's IT
          infrastructure, including hardware, software, networks, and security
          systems. We may also recommend upgrades, enhancements, or changes to
          ensure the technology environment is efficient, secure, and scalable.
        </li>
        <li>
          <strong>Technology Adoption:</strong> We research and recommend new
          technologies that could benefit the company's operations,
          competitiveness, and growth. This might include cloud computing, data
          analytics, artificial intelligence, and more.
        </li>
        <li>
          <strong>Budgeting and Resource Allocation:</strong> The Virtual
          CTO/CIO helps create technology budgets, allocating resources for
          different projects and initiatives, while ensuring cost-effectiveness
          and ROI.
        </li>
        <li>
          <strong>Security and Compliance:</strong> We oversee cybersecurity
          measures, ensuring that the company's data and systems are protected
          from potential threats and that all regulatory and compliance
          requirements are met.
        </li>
        <li>
          <strong> Vendor Management:</strong> We help evaluate and manage
          technology vendors and service providers, ensuring that the company is
          getting the best value and performance from its technology
          partnerships.
        </li>
        <li>
          <strong>Team Leadership and Management:</strong> If required our
          Virtual CTO/CIO might also provide guidance and leadership to the
          internal IT team or external contractors, helping them execute
          technology projects effectively.
        </li>
      </ol>
      <Paragraph>
        The Virtual CTO/CIO model is particularly beneficial for{' '}
        <strong>startups, small businesses,</strong> or organizations that may
        not have the resources to hire a full-time executive. It provides access
        to specialized expertise without the long-term commitment and overhead
        costs associated with a traditional executive hire. The arrangement can
        be customized based on the company's specific needs, allowing them to
        tap into strategic technology guidance as and when required.
      </Paragraph>
      <SubTitle>Cyber Security & Information Security</SubTitle>
      <Paragraph>
        Safeguarding your digital assets with comprehensive cybersecurity and
        information security solutions. Cloud security is the protection of data
        stored online via cloud computing platforms from theft, leakage, and
        deletion. Methods of providing cloud security include firewalls,
        penetration testing, obfuscation, tokenization, virtual private networks
        (VPN), and avoiding public internet connections. Cloud security is a
        form of cybersecurity.
      </Paragraph>
    </Container>
  );
};

export default WhatWeDo;
