import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-top: 60px;
  height: 250px;
  position: relative;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 0px;
    overflow: hidden;
  }
`;
const BG = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const BgContent = styled.div`
  position: absolute;
  width: 100%;
  top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    top: 50px;
    width: auto;
  }
`;

const BannerTitle = styled.p`
  font: normal normal 800 46px/63px Manrope;
  text-align: center;
  @media screen and (max-width: 768px) {
    font: normal normal 800 23px/30px Manrope;
    justify-content: center;
  }
`;

const SubTitle = styled.p`
  width: 50%;
  font: normal normal 600 24px/33px Manrope;
  justify-content: center;
  text-align: center;
  @media screen and (max-width: 768px) {
    font: normal normal normal 20px/30px Manrope;
    width: 80%;
  }
`;

const Banner = (props) => {
  return (
    <Wrapper>
      <BG src={props.image} />
      <BgContent>
        <BannerTitle>{props.title}</BannerTitle>
        <SubTitle>{props.subtitle}</SubTitle>
      </BgContent>
    </Wrapper>
  );
};

export default Banner;
