import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Banner from "../components/Banner";
import BGI from "../assets/img/banners/security.png";
import ContactComponent from "../components/ContactComponent";
// import {Card} from 'react-bootstrap';
const Wrapper = styled.div`
  width: 90%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
`;
const Container = styled.div`
  background: #cfcfcf;
  max-width: 100%;
`;

const ServiceContainer = styled.div`
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  align-content: space-between;
  text-align: left;
  padding-top: 2%;
  padding-bottom: 2%;
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  color: #444;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 30px;
`;

const Yus = styled.div`
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
const YusTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 10px;
`;

const Security = () => {
  return (
    <>
      <Helmet>
        <title>Best Practices for a Successful Cloud Migration Project</title>
        <meta
          name="description"
          content="We specialize in Cloud Architecture, Cloud Services, and System Integration for migration experience. We support from planning & execution to post-migration."
        />
        <meta
          name="keywords"
          content="Cloud Migration Project, Cloud Architecture, Cloud Services, cloud migration, System Integration"
        />
      </Helmet>
      <Banner
        image={BGI}
        title={"Cyber Engineering"}
        subtitle={"Cyber Security"}
      />
      <Wrapper>
        <Container style={{ background: "white" }}>
          <ServiceContainer>
            <Title>Cyber Defense Resiliency Service </Title>
            <Paragraph>
              Digital transformations bring along tremendous benefits, but not
              without threats to the privacy and security of crucial enterprise
              data. In present times, cyberattacks are rampant, and new-age
              cyber threats require dexterous strategies. Therefore, enterprises
              need to develop cyber defense resilience to counter such
              incidents.{" "}
            </Paragraph>
            <Paragraph>
              At Cylogics, we follow a customer-centric approach to create
              cybersecurity solutions that help our clients build resilient
              enterprises. We offer platform-based enterprise cybersecurity
              solutions, which are proactive, prescriptive, and cognitively
              autonomous.
            </Paragraph>
            <Paragraph>
              As a security transformation partner, we have helped our clients
              in more than 6 countries build personalized security roadmap
              aligned to their digital transformation goals. We create digital
              cyber defense capabilities to solve some of the most complex
              challenges and secure digital transformations at scale.
            </Paragraph>
          </ServiceContainer>
        </Container>

        <Container style={{ background: "white" }}>
          <ServiceContainer>
            <Title>Our Offerings.</Title>
            <Paragraph>
              Empowering security in the digital realm, our comprehensive
              services cover cloud security, identity management, application
              fortification, threat detection, and collaborative
              implementations, ensuring robust protection against evolving cyber
              threats for your enterprise's peace of mind.
            </Paragraph>
            <Yus>
              <YusTitle>CSPM (Cloud Security Posture Management)</YusTitle>
              <Paragraph>
                Safeguarding your cloud infrastructure is paramount in the
                digital era. Our CSPM services ensure that your cloud
                environment adheres to security best practices and compliance
                standards. We provide continuous monitoring, configuration
                management, and threat detection to maintain a robust and secure
                cloud posture.
              </Paragraph>
            </Yus>
            <Yus>
              <YusTitle> CWPP (Cloud Workload Protection Platform)</YusTitle>
              <Paragraph>
                With CWPP, we fortify your cloud workloads against evolving
                threats. Our proactive approach secures your applications and
                data within the cloud environment. We employ advanced techniques
                like real-time monitoring, behavior analysis, and automated
                responses to safeguard your critical workloads.
              </Paragraph>
            </Yus>
            <Yus>
              <YusTitle> IAM (Identity and Access Management)</YusTitle>
              <Paragraph>
                IAM is the cornerstone of secure access control. Our solutions
                enable you to manage and control user access efficiently. We
                implement robust identity governance, multifactor
                authentication, and privileged access management to prevent
                unauthorized access and ensure data integrity.
              </Paragraph>
            </Yus>
            <Yus>
              <YusTitle>Application Security</YusTitle>
              <Paragraph>
                Protecting applications from vulnerabilities is crucial. Our
                comprehensive application security services encompass code
                review, penetration testing, and secure coding practices. We
                identify and mitigate potential threats early in the development
                lifecycle to ensure your applications remain resilient against
                cyber threats.
              </Paragraph>
            </Yus>
            <Yus>
              <YusTitle>Advanced Threat & Vulnerability Management</YusTitle>
              <Paragraph>
                Our advanced threat and vulnerability management services employ
                cutting-edge technologies and methodologies to detect,
                prioritize, and mitigate potential threats. We offer proactive
                threat hunting, vulnerability scanning, and remediation
                strategies to prevent breaches and minimize risks effectively.
              </Paragraph>
            </Yus>
            <Yus>
              <YusTitle>SOC (Security Operations Center)</YusTitle>
              <Paragraph>
                Our SOC services provide continuous monitoring, threat
                detection, and incident response. We operate a dedicated
                security operations center equipped with skilled analysts and
                advanced tools to detect, analyze, and respond to security
                incidents promptly, ensuring your environment remains secure
                round the clock.
              </Paragraph>
            </Yus>
            <Yus>
              <YusTitle>Vulnerability Assessment</YusTitle>
              <Paragraph>
                Identifying and assessing vulnerabilities is critical in
                preempting potential attacks. Our vulnerability assessment
                services offer comprehensive scans and assessments to pinpoint
                weaknesses within your infrastructure, applications, and
                networks, allowing for timely remediation.
              </Paragraph>
            </Yus>
            <Yus>
              <YusTitle>Collaboration for Security Implementations</YusTitle>
              <Paragraph>
                Collaboration is key to successful security implementations. We
                work closely with your team, providing expert guidance and
                support throughout the implementation process. Our collaborative
                approach ensures seamless integration of security measures into
                your existing systems, optimizing protection while minimizing
                disruptions.
              </Paragraph>
            </Yus>
          </ServiceContainer>
        </Container>
      </Wrapper>
      <ContactComponent />
    </>
  );
};

export default Security;
