import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #f0f0f0;
  max-width: 100%;
  margin: auto auto;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
const Left = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const Right = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const Card = styled.div`
  height: 90%;
  width: 100%;
  border-radius: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  flex: 1 1 250px;
`;

const Title = styled.h2`
  color: var(--e-global-color-f57c355);
  font-family: 'Poppins', Sans-serif;
  font-size: 35px;
  font-weight: 700;
  text-transform: uppercase;
`;
const Desc = styled.p``;

const MissionVision = () => {
  return (
    <Container>
      <Left>
        <Card>
          <Title>Vision</Title>
          <Desc>
            "Leading Innovation in Cloud and IT Solutions for a Digitally
            Transformed Tomorrow"
          </Desc>
        </Card>
      </Left>
      <Right>
        <Card>
          <Title>Mission</Title>
          <Desc>
            "At Cylogics, we empower businesses with advanced Cloud and IT
            solutions, fostering digital transformation through innovation,
            expertise, and unwavering dedication."
          </Desc>
        </Card>
      </Right>
    </Container>
  );
};

export default MissionVision;
