import React, {useRef} from 'react';
import emailjs from '@emailjs/browser';
import styled from 'styled-components';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';

const Container = styled.div`
  padding-top: 20px;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    width: 90%;
    padding-left: 0;
    overflow: hidden;
  }
`;
const Title = styled.h3`
  margin-left: auto;
  margin-right: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  font-family: Manrope;
  color: #000000;
`;
const TextField = styled.input`
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  height: 40px;
  width: 80%;
  padding-left: 30px;
  background: #efefef 0% 0% no-repeat padding-box;
`;
const MessageBox = styled.textarea`
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  height: 50px;
  width: 80%;
  padding-left: 30px;
  background: #efefef 0% 0% no-repeat padding-box;
`;

const Button = styled.button`
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  width: 150px;
  height: 50px;
  background: #7b1845 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  /* line-height: 30px; */
  font-family: Manrope;
  color: #ffffff;
  /* text-transform: uppercase; */
`;

const ContactForm = () => {
  const form = useRef();

  const sendEmail = e => {
    e.preventDefault();

    emailjs
      .sendForm(
        'cylogicsind',
        'template_5u5lreg',
        form.current,
        'yJ1PEANdigHwdlKmD'
      )
      .then(
        result => {
          console.log(result.text);
          form.current.reset();
          toast.success('Thank you! We will revert shortly');
        },
        error => {
          console.log(error.text);
        }
      );
  };
  return (
    <>
      <form ref={form} onSubmit={sendEmail}>
        <Container>
          <Title>Contact Us</Title>
          <TextField
            className="form-group"
            placeholder="Name"
            type="text"
            id="name"
            name="user_name"
            required
          ></TextField>
          <TextField
            className="form-group"
            placeholder="Email"
            type="email"
            id="email"
            name="user_email"
            required
          ></TextField>
          <TextField
            className="form-group"
            placeholder="Phone Number"
            type="phone"
            id="phone"
            name="user_phone"
            required
          ></TextField>
          <MessageBox
            className="form-group"
            placeholder="Type Here"
            id="message"
            name="message"
            required
          ></MessageBox>
          <Button className='"contactBtn' type="submit" value="send">
            Submit
          </Button>
          <ToastContainer />
        </Container>
      </form>
    </>
  );
};

export default ContactForm;
