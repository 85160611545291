import React from "react";
import styled, { keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShieldAlt,
  faCediSign,
  faCloud,
  faCloudBolt,
  faDiagramProject,
  faEye,
  faFlaskVial,
  faGears,
  faHandHoldingHand,
  faPersonDotsFromLine,
  faServer,
  faShieldDog,
  faShieldHalved,
} from "@fortawesome/free-solid-svg-icons";
const VerticalsHeading = styled.h2`
  color: var(--e-global-color-f57c355);
  font-family: "Poppins", Sans-serif;
  font-size: 35px;
  font-weight: 700;
  text-transform: uppercase;
`;
const VerticalssSection = styled.section`
  background-color: #f8f8f8;
  padding: 20px 0;
  text-align: center;
`;

const VerticalssContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
const zoomIn = keyframes`
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const VerticalsCard = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 10px;
  flex: 1 1 250px;
  max-width: 400px;
  animation: ${zoomIn} 0.5s ease-in-out forwards;
`;
const VerticalsIcon = styled.div`
  padding: 20px;
`;
const iconStyle = {
  fontSize: "35px",
  color: "#024386",

  // color: '#7b1845',
};
const VerticalsTitle = styled.h3`
  color: var(--e-global-color-f57c355);
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
`;

const VerticalsDescription = styled.p`
  padding: 10px;
  text-align: left;
  font-size: 16px;
  color: #555555;
`;

const Explore = styled.a`
  color: #54173b;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;
  margin-left: 20px;
  &:hover {
    color: #555555;
  }
`;

// Verticalss Component
const Verticalss = () => {
  return (
    <VerticalssSection>
      <VerticalsHeading>Our Verticals</VerticalsHeading>
      <VerticalssContainer>
        <VerticalsCard>
          <VerticalsIcon>
            <FontAwesomeIcon icon={faShieldAlt} style={iconStyle} />
          </VerticalsIcon>
          <VerticalsTitle>Cyber Engineering</VerticalsTitle>
          <VerticalsDescription>
            <ul>
              <li>- Cybersecurity</li>
              <li>- System Design and Development</li>
              <li>- Network Security</li>
              <li>- Threat Intelligence and Management</li>
              <li>- Incident Response and Recovery</li>
              <li>- Compliance and Legal Standards</li>
              <li>- Ethical Hacking and Penetration Testing</li>
              <li>- Emerging Technologies</li>
            </ul>
          </VerticalsDescription>
          <Explore href="/cyberengineering">Explore...</Explore>
        </VerticalsCard>
        <VerticalsCard>
          <VerticalsIcon>
            <FontAwesomeIcon icon={faCloud} style={iconStyle} />
          </VerticalsIcon>
          <VerticalsTitle>Cloud Services</VerticalsTitle>
          <VerticalsDescription>
            <ul>
              <li>- Cloud Architecture</li>
              <li>- Cloud Migration</li>
              <li>- Managed Cloud Services</li>
              <li>- Cloud Optimization</li>
              <li>- Infrastructure As Codey</li>
              <li>- Containerization / Docker</li>
              <li>- DevOPS</li>
              <li>- DevSecOPS</li>
            </ul>
          </VerticalsDescription>
          <Explore href="/cloud">Explore...</Explore>
        </VerticalsCard>
        <VerticalsCard>
          <VerticalsIcon>
            <FontAwesomeIcon icon={faGears} style={iconStyle} />
          </VerticalsIcon>
          <VerticalsTitle>IT Consulting</VerticalsTitle>
          <VerticalsDescription>
            <ul>
              <li>- Digital Transformation Consulting</li>
              <li>- Business Intelligence (BI)</li>
              <li>- IT Infrastructure Optimization</li>
              <li>- ERP Implementation and Integration</li>
              <li>- VCISO / VCTO / VCIO</li>
              <li>- IT Outsourcing and Managed Services</li>
              <li>- Business Continuity & Disaster Recovery</li>
              <li>- Custom Software Development</li>
            </ul>
          </VerticalsDescription>
          <Explore href="/consulting">Explore...</Explore>
        </VerticalsCard>
      </VerticalssContainer>
    </VerticalssSection>
  );
};

export default Verticalss;
