import React from 'react';
import styled, {keyframes} from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCediSign,
  faCloud,
  faCloudBolt,
  faDiagramProject,
  faEye,
  faFlaskVial,
  faGears,
  faHandHoldingHand,
  faPersonDotsFromLine,
  faServer,
  faShieldDog,
  faShieldHalved,
} from '@fortawesome/free-solid-svg-icons';
const ServiceHeading = styled.h2`
  color: var(--e-global-color-f57c355);
  font-family: 'Poppins', Sans-serif;
  font-size: 35px;
  font-weight: 700;
  text-transform: uppercase;
`;
const ServicesSection = styled.section`
  background-color: #f8f8f8;
  padding: 20px 0;
  text-align: center;
`;

const ServicesContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
const zoomIn = keyframes`
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const ServiceCard = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 10px;
  flex: 1 1 250px;
  max-width: 300px;
  animation: ${zoomIn} 0.5s ease-in-out forwards;
`;
const ServiceIcon = styled.div`
  padding: 20px;
`;
const iconStyle = {
  fontSize: '35px',
  color: '#024386',
  // color: '#7b1845',
};
const ServiceTitle = styled.h3`
  color: var(--e-global-color-f57c355);
  font-family: 'Poppins', Sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const ServiceDescription = styled.p`
  padding: 10px;
  text-align: left;
  font-size: 16px;
  color: #555555;
`;

// Services Component
const Services = () => {
  return (
    <ServicesSection>
      <ServiceHeading>Our Services</ServiceHeading>
      <ServicesContainer>
        <ServiceCard>
          <ServiceIcon>
            <FontAwesomeIcon icon={faShieldHalved} style={iconStyle} />
          </ServiceIcon>
          <ServiceTitle>Cyber Security & Information Security</ServiceTitle>
          <ServiceDescription>
            Cybersecurity safeguards digital systems, while Information Security
            protects sensitive data, ensuring comprehensive protection against
            threats and breaches.
          </ServiceDescription>
        </ServiceCard>
        <ServiceCard>
          <ServiceIcon>
            <FontAwesomeIcon icon={faCloud} style={iconStyle} />
          </ServiceIcon>
          <ServiceTitle>Cloud Migration</ServiceTitle>
          <ServiceDescription>
            Seamless transition to the cloud, unlocking scalability and agility
            for your digital transformation journey.
          </ServiceDescription>
        </ServiceCard>
        <ServiceCard>
          <ServiceIcon>
            <FontAwesomeIcon icon={faCloudBolt} style={iconStyle} />
          </ServiceIcon>
          <ServiceTitle>Cloud Architecture</ServiceTitle>
          <ServiceDescription>
            Cloud architecture, designed with security as a foundational
            principle, ensures robust protection of data and systems through
            integrated security measures across the entire infrastructure.
          </ServiceDescription>
        </ServiceCard>
        <ServiceCard>
          <ServiceIcon>
            <FontAwesomeIcon icon={faEye} style={iconStyle} />
          </ServiceIcon>
          <ServiceTitle>SOC</ServiceTitle>
          <ServiceDescription>
            SOC (Security Operations Center) monitors, detects, and responds to
            cybersecurity threats in real-time, ensuring continuous protection
            for your organization's digital assets.
          </ServiceDescription>
        </ServiceCard>

        <ServiceCard>
          <ServiceIcon>
            <FontAwesomeIcon icon={faCediSign} style={iconStyle} />
          </ServiceIcon>
          <ServiceTitle>IT Compliance & Audit</ServiceTitle>
          <ServiceDescription>
            Ensuring regulatory adherence and data security through
            comprehensive IT compliance solutions.
          </ServiceDescription>
        </ServiceCard>
        <ServiceCard>
          <ServiceIcon>
            <FontAwesomeIcon icon={faDiagramProject} style={iconStyle} />
          </ServiceIcon>
          <ServiceTitle>Project Management</ServiceTitle>
          <ServiceDescription>
            Efficient planning and execution of projects, delivering on time and
            within budget.
          </ServiceDescription>
        </ServiceCard>

        <ServiceCard>
          <ServiceIcon>
            <FontAwesomeIcon icon={faPersonDotsFromLine} style={iconStyle} />
          </ServiceIcon>
          <ServiceTitle>Virtual CTO / CIO</ServiceTitle>
          <ServiceDescription>
            A Virtual CTO/CIO offers strategic technology guidance and
            oversight, aligning business goals with technological solutions to
            optimize operations and innovation remotely.
          </ServiceDescription>
        </ServiceCard>
        <ServiceCard>
          <ServiceIcon>
            <FontAwesomeIcon icon={faGears} style={iconStyle} />
          </ServiceIcon>
          <ServiceTitle>IT Consulting</ServiceTitle>
          <ServiceDescription>
            Expert guidance and solutions to optimize your IT infrastructure and
            strategy, driving efficiency and innovation for your business.
          </ServiceDescription>
        </ServiceCard>
        <ServiceCard>
          <ServiceIcon>
            <FontAwesomeIcon icon={faShieldDog} style={iconStyle} />
          </ServiceIcon>
          <ServiceTitle>CSPM</ServiceTitle>
          <ServiceDescription>
            CSPM (Cloud Security Posture Management) ensures continuous security
            and compliance of cloud environments by monitoring, detecting, and
            remediating misconfigurations and vulnerabilities.
          </ServiceDescription>
        </ServiceCard>
        <ServiceCard>
          <ServiceIcon>
            <FontAwesomeIcon icon={faHandHoldingHand} style={iconStyle} />
          </ServiceIcon>
          <ServiceTitle>CWPP</ServiceTitle>
          <ServiceDescription>
            CWPP (Cloud Workload Protection Platform) secures and monitors cloud
            workloads, providing visibility and protection against threats and
            vulnerabilities within cloud-based applications and services.
          </ServiceDescription>
        </ServiceCard>
        <ServiceCard>
          <ServiceIcon>
            <FontAwesomeIcon icon={faServer} style={iconStyle} />
          </ServiceIcon>
          <ServiceTitle>IAC</ServiceTitle>
          <ServiceDescription>
            IAC (Infrastructure as Code) automates and manages IT infrastructure
            through code, enabling efficient deployment, scalability, and
            consistent configuration across cloud environments.
          </ServiceDescription>
        </ServiceCard>
        <ServiceCard>
          <ServiceIcon>
            <FontAwesomeIcon icon={faFlaskVial} style={iconStyle} />
          </ServiceIcon>
          <ServiceTitle>Vulnerability Assessment</ServiceTitle>
          <ServiceDescription>
            Vulnerability Assessment identifies, evaluates, and prioritizes
            security weaknesses within systems or networks to proactively
            prevent potential cyber threats.
          </ServiceDescription>
        </ServiceCard>
      </ServicesContainer>
    </ServicesSection>
  );
};

export default Services;
