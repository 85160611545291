import React from "react";
import styled from "styled-components";

// Styled components for the ConsultationBar
const ConsultationBarWrapper = styled.div`
  width: 100%;
  height: 100px;
  background: #616060;
  color: white;
  padding: 30px 0;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const ConsultationText = styled.h2`
  margin-top: auto;
  margin-bottom: auto;
`;

const ConsultationButton = styled.button`
  margin-top: auto;
  display: inline-block;
  padding: 7px 28px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #54173b;
  background: #fff;
  border: 3px solid #fff;
  border-radius: 30px;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  font-size: 16px;
  text-decoration: none;

  &:hover {
    background-color: #54173b;
    border: 1px solid #54173b;
    color: #fff;
  }
`;

// ConsultationBar component
const ConsultationBar = () => {
  return (
    <ConsultationBarWrapper>
      <ConsultationText>Get a Free Consultation</ConsultationText>
      <a href="/contact">
        <ConsultationButton>Contact Us</ConsultationButton>
      </a>
    </ConsultationBarWrapper>
  );
};

export default ConsultationBar;
