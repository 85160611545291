import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Banner from "../components/Banner";
import BGI from "../assets/img/banners/cloudservices.jpg";
import ContactComponent from "../components/ContactComponent";
// import {Card} from 'react-bootstrap';
const Wrapper = styled.div`
  width: 90%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const Container = styled.div`
  background: #cfcfcf;
  max-width: 100%;
`;

const ServiceContainer = styled.div`
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  align-content: space-between;
  text-align: left;
  padding-top: 2%;
  padding-bottom: 2%;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    padding: 20px;
  }
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  color: #444;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 30px;
`;

const Yus = styled.div`
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;
const YusTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 10px;
`;

const CloudServices = () => {
  return (
    <>
      <Helmet>
        <title>Best Practices for a Successful Cloud Migration Project</title>
        <meta
          name="description"
          content="We specialize in Cloud Architecture, Cloud Services, and System Integration for migration experience. We support from planning & execution to post-migration."
        />
        <meta
          name="keywords"
          content="Cloud Migration Project, Cloud Architecture, Cloud Services, cloud migration, System Integration"
        />
      </Helmet>
      <Banner
        image={BGI}
        title={"Cloud Services"}
        subtitle={"Digital Transformation"}
      />
      <Wrapper>
        <Container style={{ background: "white" }}>
          <ServiceContainer>
            <Title>Cloud Strategy & Consulting </Title>
            <Paragraph>
              "At CYLOGICS, our Cloud Strategy & Consulting services are
              tailored to elevate your business operations to new heights. We
              specialize in designing comprehensive cloud strategies that align
              seamlessly with your organizational goals. Our expert consultants
              work closely with you to analyze your current infrastructure,
              understand your unique business needs, and propose optimized cloud
              solutions. From migration strategies to architecting secure and
              scalable cloud environments, we offer tailored consultancy to
              maximize efficiency, minimize costs, and ensure a smooth
              transition to the cloud. Partner with us to leverage cutting-edge
              technologies, streamline operations, and drive innovation through
              our strategic cloud consulting expertise."
            </Paragraph>
          </ServiceContainer>
        </Container>
        <Container>
          <ServiceContainer>
            <Title>Drive Business Agility And Efficiency With Cloud</Title>
            <Paragraph>
              Legacy architectures can no longer cope with the explosive growth
              of devices, systems, applications, and data. Organizations need an
              IT environment with flexibility, scalability, and performance to
              meet these demands. Cloud delivers unparalleled agility,
              efficiency, and innovation. Organizations are taking advantage of
              cloud consulting services to accelerate business operations and
              enhance efficiency.
            </Paragraph>
          </ServiceContainer>
        </Container>
        <Container style={{ background: "white" }}>
          <ServiceContainer>
            <Title>Jump Start Your Cloud Journey</Title>
            <Paragraph>
              Cloud delivers unparalleled agility, efficiency, and innovation.
              Apart from using the cloud as a tool to backup data, it
              facilitates reducing OPEX and CAPEX with a faster marketing
              experience. Organizations are migrating to the cloud to accelerate
              business operations and enhance efficiency. But this is possible
              only when implemented through a comprehensive cloud strategy. Our
              team of certified cloud consultants helps you craft the right
              cloud-first strategy aligned with your business objectives.
            </Paragraph>
          </ServiceContainer>
        </Container>
        <Container>
          <ServiceContainer>
            <Title>Our Cloud Consulting Services</Title>
            <Paragraph>
              Our cloud consulting offerings are designed to support you through
              the decision-making process that ensures you ask all the right
              questions, get clinical answers upfront, and make informed cloud
              and IT decisions that will help you accomplish your business
              objectives.
              <ul>
                <li>- Cloud Transformation</li>
                <li>- Strategy Roadmap</li>
                <li>- Readiness Assessment</li>
                <li>- Architecture Advisory</li>
                <li>- Risk Assessment & Mitigation</li>
                <li>- Security & Compliance</li>
              </ul>
            </Paragraph>
          </ServiceContainer>
        </Container>
        <Container style={{ background: "white" }}>
          <ServiceContainer>
            <Title>
              Why Choose Cylogics For Cloud Strategy & Consulting Services?
            </Title>
            <Paragraph>
              Our cloud solutions enable organizations to transform their
              traditional IT processes and help them improve productivity, lower
              costs, and reduce the time-to-market.
            </Paragraph>
            <Yus>
              <YusTitle>Agile Approach</YusTitle>
              <Paragraph>
                Our methodology is grounded in agility, ensuring flexibility and
                adaptability throughout the project lifecycle. We employ dynamic
                strategies to swiftly respond to evolving requirements,
                guaranteeing a responsive and efficient service deliver.
              </Paragraph>
            </Yus>
            <Yus>
              <YusTitle>Skilled Developers</YusTitle>
              <Paragraph>
                At Cylogics, we boast a team of highly skilled and experienced
                developers adept at leveraging the latest cloud technologies.
                Their expertise enables us to craft tailored solutions aligned
                with your unique business needs.
              </Paragraph>
            </Yus>
            <Yus>
              <YusTitle>100% Transparency</YusTitle>
              <Paragraph>
                We uphold the highest standards of transparency in our
                operations. We maintain open communication channels, offering
                clients real-time insights into the project's progress and
                fostering trust through clear and consistent reporting.
              </Paragraph>
            </Yus>
            <Yus>
              <YusTitle>On-Time Delivery</YusTitle>
              <Paragraph>
                We are committed to meeting deadlines and delivering projects
                punctually. Our streamlined processes and diligent team ensure
                that your project is completed within the agreed-upon timeframe
                without compromising quality.
              </Paragraph>
            </Yus>
            <Yus>
              <YusTitle>Quality Service</YusTitle>
              <Paragraph>
                Cylogics prioritizes quality in every aspect of service
                delivery. From conceptualization to deployment, we maintain
                rigorous quality checks, ensuring that the solutions provided
                are robust, reliable, and scalable.
              </Paragraph>
            </Yus>
            <Yus>
              <YusTitle>Transparent Pricing</YusTitle>
              <Paragraph>
                Our pricing structures are transparent and devoid of hidden
                costs. We believe in upfront communication regarding pricing,
                ensuring that our clients are aware of the costs involved at
                every stage of the engagement.
              </Paragraph>
            </Yus>
            <Yus>
              <YusTitle>Security by Design</YusTitle>
              <Paragraph>
                Security is embedded into our solutions from the outset. We
                follow industry best practices and incorporate robust security
                measures into the architecture, guaranteeing a secure
                environment for your data and operations.
              </Paragraph>
            </Yus>
          </ServiceContainer>
        </Container>
      </Wrapper>
      <ContactComponent />
    </>
  );
};

export default CloudServices;
