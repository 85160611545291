import React from 'react';
import styled from 'styled-components';

const Mtitle = styled.h2`
  color: var(--e-global-color-f57c355);
  font-family: 'Poppins', Sans-serif;
  font-size: 35px;
  font-weight: 700;
  text-transform: uppercase;
  @media screen and (max-width: 768px) {
    padding: 10px;
  }
`;
const Subtitle = styled.h2`
  color: var(--e-global-color-f57c355);
  font-family: 'Poppins', Sans-serif;
  font-size: 24px;
  font-weight: 600;
  /* text-transform: uppercase; */
`;
const AboutSection = styled.section`
  /* background-color: #e0e0e0; */
  padding: 40px;
  text-align: center;
  @media screen and (max-width: 768px) {
    padding: 10px;
  }
`;
const Container = styled.div`
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  color: #423f3f;
  @media screen and (max-width: 768px) {
    display: block;
    flex-wrap: wrap;
    padding: 0;
  }
`;
const Left = styled.div`
  flex: 1;
  width: 50%;
  text-align: left;
  padding: 20px;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;
const Right = styled.div`
  flex: 1;
  width: 50%;
  padding: 20px;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

const AboutImg = styled.img`
  margin-top: 3%;
  width: 100%;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
    margin-top: 0;
  }
`;
const AboutUs = () => {
  return (
    <AboutSection>
      <Mtitle>About Us</Mtitle>
      <Container>
        <Left>
          <Subtitle>Overview</Subtitle>
          <p>
            Welcome to Cylogics, your trusted Managed IT Services partner.
            Specializing in Cloud Architecture and Security, we deliver tailored
            solutions prioritizing robustness and scalability. Our experts
            ensure seamless integration, proactive support, and cutting-edge
            strategies. Let us optimize your technology for secure, future-ready
            success.
          </p>
          <Subtitle>Our Expertise</Subtitle>
          <p>
            Specializing in Zero Trust and Shift Left methodologies, we offer
            comprehensive, cost-efficient multi-cloud security strategies. Our
            approach merges cutting-edge practices with industry standards,
            emphasizing efficiency and affordability. We ensure ease of
            management, delivering tailored solutions for robust and secure
            operations.
          </p>
        </Left>
        <Right>
          <AboutImg src="/assets/about-img.png" alt="" />
        </Right>
      </Container>
    </AboutSection>
  );
};

export default AboutUs;
