import { Room, Phone, Email } from "@material-ui/icons";
import React from "react";
import styled from "styled-components";
import logoW from "../assets/img/logoWhite2x.png";
import fb from "../assets/img/sm/fb.png";
import insta from "../assets/img/sm/insta.png";
import tweet from "../assets/img/sm/tw.png";
import li from "../assets/img/sm/li.png";
import wa from "../assets/img/sm/wa.png";

const Wrapper = styled.div``;
const Container = styled.div`
  display: flex;
  background-color: #00396b;
  color: white;
  @media screen and (max-width: 768px) {
    display: block;
    flex-wrap: wrap;
  }
`;
const Left = styled.div`
  flex: 1;
  width: 33.3%;
  background: #01396a 0% 0% no-repeat padding-box;
  padding: 30px 00px 0px 30px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const Logo = styled.img`
  width: 170px;

  position: relative;
`;
const Desc = styled.p`
  width: 90%;
  margin-top: 30px;
  margin-bottom: 30px;
  line-height: 2em;
`;
const SocialContainer = styled.div`
  display: flex;
  margin-bottom: 25px;
`;
const SocialIconBG = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  display: flex;
  background: #002c53 0% 0% no-repeat padding-box;
  align-items: center;
  margin-right: 10px;
`;

const SocialIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 36px;
  height: 36px;
  margin: -18px 0px 0px -18px;
`;
const Center = styled.div`
  background-color: #002b52;
  flex: 1;
  padding: 20px;
`;
const Title = styled.h3`
  margin-bottom: 40px;
  font-family: manrope;
  font-size: 25px;
`;
const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
`;
const ListItem = styled.li`
  width: 50%;
  margin-bottom: 10px;
  font-family: manrope;
  font-size: 16px;
`;
const Right = styled.div`
  flex: 1;
  padding: 20px;
`;
const ContactItem = styled.h4`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 16px;
  font-family: Manrope;
`;
const Copy = styled.div`
  background: #54173b 0% 0% no-repeat padding-box;
  padding-left: 30px;
  height: 40px;
  line-height: 40px;
`;
const Span = styled.div`
  margin-right: 10px;
  font-family: Manrope;
  color: white;
`;

const CopyLink = styled.a`
  text-decoration: none;
  color: white;
  font-family: Manrope;
  font-size: 14px;
`;

const PPLink = styled.a`
  text-decoration: none;
  color: white;
  font-family: Manrope;
  font-weight: bold;
  font-size: 15px;
`;

const Clickable = styled.a`
  text-decoration: none;
  color: white;
  font-family: Manrope;
`;

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <Left>
          <Logo src={logoW} alt="" srcset="" />

          <Desc>
            Cylogics, established in mid-2018, began with a vision of offering
            Cloud Consultancy Services, specializing in Cloud
            Design/Architecture and Cloud Migration Project Management. As the
            pandemic unfolded, we adapted and expanded our services to include
            IT Consulting, Managed Cloud Services, Project Consultancy, Software
            development outsourcing, and more, driven by the demands of our
            valued customers.
          </Desc>
          <SocialContainer>
            <SocialIconBG>
              <a
                href="https://www.facebook.com/Cylogics"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialIcon src={fb} />
              </a>
            </SocialIconBG>
            <SocialIconBG>
              <a
                href="https://www.instagram.com/cylogics"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialIcon src={insta} />
              </a>
            </SocialIconBG>
            <SocialIconBG>
              <a
                href="https://www.twitter.com/cylogics"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialIcon src={tweet} />
              </a>
            </SocialIconBG>
            <SocialIconBG>
              <a
                href="https://www.linkedin.com/company/cylogics"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialIcon src={li} />
              </a>
            </SocialIconBG>
            <SocialIconBG>
              <a
                href="https://wa.me/+917594999555"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SocialIcon src={wa} />
              </a>
            </SocialIconBG>
          </SocialContainer>
        </Left>
        <Center>
          <Title>Useful Links</Title>
          <List>
            <ListItem>
              <a href="/home" style={{ textDecoration: "none" }}>
                Home
              </a>
            </ListItem>
            <ListItem>
              <a href="/whatsCloud" style={{ textDecoration: "none" }}>
                What is the cloud?
              </a>
            </ListItem>
            <ListItem>
              <a href="/about" style={{ textDecoration: "none" }}>
                About
              </a>
            </ListItem>

            <ListItem>
              <a href="/paas" style={{ textDecoration: "none" }}>
                What is PaaS?
              </a>
            </ListItem>
            <ListItem>
              <a href="/whatsCloud" style={{ textDecoration: "none" }}>
                Testimonials
              </a>
            </ListItem>
            <ListItem>
              <a href="/saas" style={{ textDecoration: "none" }}>
                What is SaaS?
              </a>
            </ListItem>
            <ListItem>
              <a href="/services" style={{ textDecoration: "none" }}>
                Services
              </a>
            </ListItem>
            <ListItem>
              <a href="/containerize" style={{ textDecoration: "none" }}>
                What is a container?
              </a>
            </ListItem>
          </List>
        </Center>
        <Right>
          <Title>Contact</Title>
          <h5 style={{ marginLeft: "10px", marginBottom: "20px" }}>
            Cylogics International Solutions Pvt. Ltd
          </h5>
          <ContactItem>
            <Room style={{ marginLeft: "10px", marginRight: "10px" }} />
            Kerala - 680541.
          </ContactItem>
          <ContactItem>
            <Phone style={{ marginLeft: "10px", marginRight: "10px" }} />
            <Clickable href="tel:00917594999555">+91 7594 999 555</Clickable>
          </ContactItem>
          <ContactItem>
            <Email style={{ marginLeft: "10px", marginRight: "10px" }} />
            <Clickable href="mailto:info@cylogics.com">
              info@cylogics.com
            </Clickable>
          </ContactItem>
        </Right>
      </Container>
      <Copy>
        <Span>
          © <CopyLink href="https://cylogics.com">Cylogics</CopyLink> | 2022 ||
          <PPLink href="/privacypolicy"> Privacy Policy</PPLink>
        </Span>
      </Copy>
      <a
        href="https://web.whatsapp.com/send?phone=917594999555"
        class="whatsapp-float"
        target="_blank"
        rel="noreferrer"
      >
        <img src="assets/img/wa.png" alt="WhatsApp" class="whatsapp-icon" />
      </a>
    </Wrapper>
  );
};

export default Footer;
