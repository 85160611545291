import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import careersData from "../data/Careers.json";

const Container = styled.div`
  max-width: 100vw;
  margin: 0 auto;
  padding: 20px;
`;

const SearchBar = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const JobList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const JobItem = styled.li`
  width: 100%;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
`;
const Label = styled.span`
  font-weight: bold;
  font-size: 1em;
`;
const JobTitle = styled.h2`
  margin: 0 0 10px 0;
`;

const JobDescription = styled.p`
  margin: 0;
`;

const iconStyle = {
  fontSize: "25px",
  color: "#024386",

  // color: '#7b1845',
};

const CareersComp = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredJobs, setFilteredJobs] = useState(careersData);

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    setFilteredJobs(
      careersData.filter(
        (job) =>
          job.title.toLowerCase().includes(term) ||
          job.description.toLowerCase().includes(term)
      )
    );
  };

  return (
    <Container>
      <SearchBar
        type="text"
        placeholder="Search for a job..."
        value={searchTerm}
        onChange={handleSearch}
      />
      <JobList>
        {filteredJobs.length > 0 ? (
          filteredJobs.map((job) => (
            <JobItem key={job.id}>
              <JobTitle>
                <FontAwesomeIcon icon={faBriefcase} style={iconStyle} /> -
                {job.title}
              </JobTitle>
              <Label>Job Description</Label>
              <JobDescription>{job.description}</JobDescription>

              <JobDescription>
                <Label>Location</Label> : {job.location}
              </JobDescription>

              <JobDescription>
                <Label>Experiance</Label> : {job.experiance}
              </JobDescription>

              <JobDescription>
                <Label>Type</Label> : {job.type}
              </JobDescription>
              <JobDescription>
                <Label>Key Responsibilities</Label> :
                <ol>
                  {job.kra.map((k, index) => (
                    <li key={index}>{k}</li>
                  ))}
                </ol>
              </JobDescription>

              <JobDescription>
                <ul>
                  <Label>Qualifications</Label> :
                  {job.qualification.map((q, index) => (
                    <li key={index}>{q}</li>
                  ))}
                </ul>
              </JobDescription>
            </JobItem>
          ))
        ) : (
          <JobItem>No jobs found.</JobItem>
        )}
      </JobList>
    </Container>
  );
};

export default CareersComp;
