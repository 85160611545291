// Layout2.js
import React from 'react';
import {Outlet} from 'react-router-dom';

const Layout2 = () => {
  return (
    <div className="Layout2-container">
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default Layout2;
