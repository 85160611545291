import React from 'react';
import BGI from '../assets/img/banners/ContactImage.png';
import styled from 'styled-components';
import ContactForm from '../components/ContactForm';
const Wrapper = styled.div`
  background-color: #e5e2e2;
  margin-top: 80px;
  /* margin-bottom: 30px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 5%;
  @media screen and (max-width: 768px) {
    padding: 0;
    justify-content: center;
  }
`;

const Left = styled.div`
  width: 50%;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const Title = styled.h3`
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  color: #7b1845;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 50px;
  font-family: Manrope;
`;
const Paragraph = styled.p`
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  font-family: Manrope;
  color: #000000;
`;

const Image = styled.img`
  margin-left: auto;
  margin-right: auto;
  width: 400px;
`;
const Right = styled.div`
  width: 40%;
  height: 500px;
  border: 2px solid #d3d3d3;
  border-radius: 10px;
  @media screen and (max-width: 768px) {
    width: 100%;
    border: none;
  }
`;
const ContactComponent = () => {
  return (
    <Wrapper>
      <Left>
        <Image src={BGI} />
        <Title>Get in Touch with US</Title>
        <Paragraph>Lets discuss on your project.</Paragraph>
      </Left>
      <Right>
        <ContactForm />
      </Right>
    </Wrapper>
  );
};

export default ContactComponent;
