import React from "react";
import "../mycss.css";
import BannerBG from "../components/BannerBG";
import Benifits from "../components/Benifits";
import { Helmet } from "react-helmet";
import BGV from "../assets/background/bg.mp4";
import Services from "../components/Services";
import AboutUs from "../components/AboutUs";
import ConsultationBar from "../components/ConsultationBar";
import BoxImageAnimator from "../components/BoxImageAnimator";
import Verticalss from "../components/Verticals";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Transform Your Business with Scalable Cloud Solutions</title>
        <meta
          name="description"
          content="Maximize business potential with our advanced cloud solutions. We provides scalable, secure, and reliable cloud solutions tailored to your unique needs."
        />
        <meta
          name="keywords"
          content="Cloud solutions, Cloud Design, Cloud Migration Project"
        />
      </Helmet>
      <BannerBG bg={BGV} />
      <Benifits />
      <AboutUs />
      <Verticalss />
      {/* <Services /> */}
      <ConsultationBar />
      {/* <CaseStudies /> */}
      <BoxImageAnimator />
      {/* <ClientTestimonial /> */}
      {/* <Partners /> */}
      {/* <Products /> */}
    </>
  );
};

export default Home;
