import React from "react";
import styled, { keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCediSign,
  faCloud,
  faCloudBolt,
  faDiagramProject,
  faEye,
  faFlaskVial,
  faGears,
  faHandHoldingHand,
  faPersonDotsFromLine,
  faServer,
  faBox,
  faListCheck,
  faShieldDog,
  faShieldHalved,
} from "@fortawesome/free-solid-svg-icons";

const CaseHeading = styled.h2`
  color: var(--e-global-color-f57c355);
  font-family: "Poppins", Sans-serif;
  font-size: 35px;
  font-weight: 700;
  text-transform: uppercase;
`;
const CaseStudiesCSection = styled.section`
  background-color: #f8f8f8;
  padding: 20px 0;
  text-align: center;
`;

const CaseStudiesCContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
const zoomIn = keyframes`
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const CaseCard = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 10px;
  flex: 1 1 250px;
  max-width: 100%;
  animation: ${zoomIn} 0.5s ease-in-out forwards;
`;
const CaseIcon = styled.div`
  padding: 20px;
`;
const iconStyle = {
  fontSize: "35px",
  color: "#024386",
  // color: '#7b1845',
};
const CaseTitle = styled.h3`
  color: var(--e-global-color-f57c355);
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const CaseDescription = styled.p`
  padding: 10px;
  text-align: left;
  font-size: 16px;
  color: #555555;
`;

const ReadMore = styled.a``;
const ExploreButton = styled.button`
  display: inline-block;
  padding: 7px 28px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #06061b;
  background: transparent;
  border: 3px solid #06061b;
  border-radius: 30px;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  font-size: 16px;
  text-decoration: none;
  :hover {
    background: #06061b;
    color: #fff;
  }
`;
// CaseStudiesC Component
const CaseStudiesC = () => {
  return (
    <CaseStudiesCSection>
      <CaseHeading>Customer Success Stories</CaseHeading>
      <CaseStudiesCContainer>
        <CaseCard>
          <CaseIcon>
            <FontAwesomeIcon icon={faCloud} style={iconStyle} />
          </CaseIcon>
          <CaseTitle>Fintech - Cloud Migration</CaseTitle>
          <CaseDescription>
            How CYLOGICS Helped a Leading NBFC Reduce IT Costs by 30% While
            Improving Performance?
          </CaseDescription>
          <a href="/fintechcm">
            <ExploreButton>Read More...</ExploreButton>
          </a>
        </CaseCard>
        <CaseCard>
          <CaseIcon>
            <FontAwesomeIcon icon={faBox} style={iconStyle} />
          </CaseIcon>
          <CaseTitle>Kubernetes (EKS) - UAE</CaseTitle>
          <CaseDescription>
            Migration of a complete fintech payment solution to serverless
            "(Elastic Kubernetes Service)"
          </CaseDescription>
          <a href="/fintecheks">
            <ExploreButton>Read More...</ExploreButton>
          </a>
        </CaseCard>
        <CaseCard>
          <CaseIcon>
            <FontAwesomeIcon icon={faListCheck} style={iconStyle} />
          </CaseIcon>
          <CaseTitle>Compliance Enablement - Singapore</CaseTitle>
          <CaseDescription>
            Enablement of TRMG, ISO 27001 Standards to a Fintech Company
          </CaseDescription>
          <a href="">
            <ExploreButton>Read More...</ExploreButton>
          </a>
        </CaseCard>
      </CaseStudiesCContainer>
    </CaseStudiesCSection>
  );
};

export default CaseStudiesC;
