import React, { useState } from "react";
import styled from "styled-components";
import "../App.css";
import { NavLink } from "react-router-dom";
import "./Navbar.css";
import Dropdown from "./Dropdown";

const Wrapper = styled.div``;
const Logo = styled.img`
  width: 196px;
  @media screen and (max-width: 768px) {
    width: 150px;
  }
`;
const linkStyle = {
  fontSize: "1em",
  fontWeight: "bold",
  textDecoration: "none",
  paddingLeft: "30px",
  fontFamily: "manrope",
};

const Navbar = () => {
  const [dropdown, setDropdown] = useState(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(true);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  return (
    <Wrapper>
      {/* <TopBanner /> */}
      <div className="container-fullwidth nav_bg">
        <div className="row ">
          <div className="col-md-6">
            <nav className="navbar navbar-expand-md fixed-top">
              <div className="nav-container container">
                <a className="navbar-brand" href="/">
                  <Logo src="assets/LogoN.png" />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav  mb-2 mb-lg-0 nav-menu">
                    <NavLink to="/" style={linkStyle} activeclassname="active">
                      Home
                    </NavLink>
                    <NavLink
                      to="/about"
                      style={linkStyle}
                      activeclassname="active"
                    >
                      About Us
                    </NavLink>
                    <NavLink
                      to="/partners"
                      style={linkStyle}
                      activeclassname="active"
                    >
                      Partners
                    </NavLink>
                    <li onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                      <span style={linkStyle}>
                        Services <i className="fas fa-caret-down" />
                        {dropdown && <Dropdown />}
                      </span>
                    </li>
                    <NavLink
                      to="/blog"
                      style={linkStyle}
                      activeclassname="active"
                    >
                      Blog
                    </NavLink>
                    <NavLink
                      to="/careers"
                      style={linkStyle}
                      activeclassname="active"
                    >
                      Careers
                    </NavLink>
                    <NavLink
                      to="/contact"
                      style={linkStyle}
                      activeclassname="active"
                    >
                      Contact Us
                    </NavLink>
                  </ul>
                  {/* <NavLink
                    to="/contact"
                    style={linkStyle}
                    activeclassname="active"
                  >
                    <button class="col btn btn-dark-blue btn-rounded">
                      Get Quote
                    </button>
                  </NavLink> */}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Navbar;
