import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import "./pp.css";
// import Banner from '../components/Banner';
// import BGI from '../assets/img/banners/case_studies.png';

const FintechCloudMigration = () => {
  return (
    <>
      <div className="Container">
        <Helmet>
          <title>Cloud Migration - Case Studies</title>
          <meta
            name="description"
            content="Maximize business potential with our advanced cloud solutions. We provides scalable, secure, and reliable cloud solutions tailored to your unique needs."
          />
          <meta
            name="keywords"
            content="Cloud solutions, Cloud Design, Cloud Migration Project"
          />
        </Helmet>
        {/* <Banner/> */}

        <h1>
          How CYLOGICS Helped a Leading NBFC Reduce Cloud Costs by 30% While
          Improving Performance
        </h1>
        <h2>Client Overview:</h2>
        <p>
          Our client, a Non-Banking Financial Company (NBFC) with 200 branches
          across South India, was facing significant challenges due to an
          outdated on-premises infrastructure. With 8-10 servers nearing the end
          of their lifecycle and IBM withdrawing support, the client needed a
          modern, scalable, and cost-effective solution.
        </p>
        <h2>The Challenge:</h2>
        <p>
          The client's existing infrastructure was obsolete, leading to frequent
          downtime, high maintenance costs, and security vulnerabilities. They
          required a solution that not only addressed these immediate concerns
          but also provided scalability to support future growth. Additionally,
          the client needed to enhance security measures and ensure continuous
          availability across all branches and the head office.
        </p>
        <h2>The CYLOGICS Solution:</h2>
        <p>
          CYLOGICS proposed a comprehensive cloud migration strategy, moving the
          client's infrastructure to AWS Cloud. Here’s how we transformed their
          IT environment:
          <br />
          <ol>
            <li>
              Cloud Migration: We migrated their on-premises infrastructure to
              AWS Cloud, utilizing Amazon EC2 instances with Windows Server
              2022. This move immediately eliminated the need for costly
              hardware upgrades and ongoing maintenance.
            </li>
            <li>
              Database Management: To manage the client’s critical data
              efficiently, we implemented Amazon RDS with SQL Server Standard
              Edition. This not only provided better performance but also
              simplified database management and maintenance tasks.
            </li>
            <li>
              Enhanced Connectivity: We configured a secure VPN to connect all
              200 branches and the head office, ensuring seamless communication
              and data transfer. This improvement significantly reduced network
              latency and improved operational efficiency.
            </li>
            <li>
              Security Enhancements: By leveraging AWS’s robust security
              features, we implemented enhanced security measures, including
              firewalls, data encryption, and continuous monitoring. This
              approach ensured the client’s data remained secure and compliant
              with industry standards.
            </li>
            <li>
              Cost Efficiency: We adopted an ARC (Annual Recurring Cost) model
              instead of capital investment, allowing the client to spread their
              costs over time. This model provided financial flexibility and
              made budgeting easier.
            </li>
            <li>
              Improved CIA (Confidentiality, Integrity, Availability): The AWS
              cloud environment offered a high level of reliability and uptime,
              significantly improving the availability of critical services.
              Data integrity and confidentiality were ensured through encryption
              and rigorous access controls.
            </li>
            <li>
              Financial Benefits: The migration to AWS resulted in a 30%
              reduction in overall IT costs. By eliminating the need for
              physical servers and reducing data center maintenance, the client
              could allocate resources more effectively, directly impacting
              their bottom line.
            </li>
          </ol>
        </p>
        <h2>Conclusion</h2>
        <p>
          Through our partnership, CYLOGICS successfully modernized the client's
          IT infrastructure, ensuring scalability, security, and
          cost-efficiency. By transitioning to AWS Cloud, the client not only
          reduced their operational costs but also positioned themselves for
          future growth with a robust, secure, and scalable IT environment.
        </p>
      </div>
    </>
  );
};

export default FintechCloudMigration;
