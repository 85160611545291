import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Banner from "../components/Banner";
import BGI from "../assets/img/banners/security.png";
import ContactComponent from "../components/ContactComponent";
// import {Card} from 'react-bootstrap';
const Wrapper = styled.div`
  width: 90%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
`;
const Container = styled.div`
  background: #cfcfcf;
  max-width: 100%;
`;

const ServiceContainer = styled.div`
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  align-content: space-between;
  text-align: left;
  padding-top: 2%;
  padding-bottom: 2%;
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  color: #444;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 30px;
`;

const Yus = styled.div`
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
const YusTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 10px;
`;

const Consulting = () => {
  return (
    <>
      <Helmet>
        <title>Best Practices for a Successful Cloud Migration Project</title>
        <meta
          name="description"
          content="We specialize in Cloud Architecture, Cloud Services, and System Integration for migration experience. We support from planning & execution to post-migration."
        />
        <meta
          name="keywords"
          content="Cloud Migration Project, Cloud Architecture, Cloud Services, cloud migration, System Integration"
        />
      </Helmet>
      <Banner image={BGI} title={"IT Consulting"} subtitle={""} />
      <Wrapper>
        <Container style={{ background: "white" }}>
          <ServiceContainer>
            <Title>IT Consulting Services </Title>
            <Paragraph>
              At Cylogics, our tailored IT Consulting Services empower
              businesses to harness the full potential of technology, enabling
              growth, efficiency, and innovation. With a team of seasoned
              experts, we offer comprehensive solutions to address diverse IT
              challenges.
            </Paragraph>
            <Paragraph>
              Partner with Cylogics for proactive, results-driven IT Consulting
              Services. Our holistic approach and industry expertise ensure that
              your IT initiatives align with your business objectives, driving
              success in the digital landscape.
            </Paragraph>
            <Paragraph>
              Contact us today to explore how our IT Consulting Services can
              transform your business.
            </Paragraph>
          </ServiceContainer>
        </Container>

        <Container style={{ background: "white" }}>
          <ServiceContainer>
            <Yus>
              <YusTitle>IVirtual CTO / CIO Services</YusTitle>
              <Paragraph>
                Leverage our Virtual Chief Technology Officer (CTO) or Chief
                Information Officer (CIO) services to gain strategic guidance
                without the overhead of a full-time executive. Our experienced
                professionals work closely with your team to develop technology
                roadmaps, align IT strategies with business goals, and drive
                innovation.
              </Paragraph>
            </Yus>
            <Yus>
              <YusTitle> Project Management</YusTitle>
              <Paragraph>
                Our project management expertise ensures seamless execution of
                IT initiatives. From conception to implementation, we handle
                project planning, resource allocation, risk management, and
                quality assurance, ensuring successful project outcomes.
              </Paragraph>
            </Yus>
            <Yus>
              <YusTitle> Building IT Departments</YusTitle>
              <Paragraph>
                Need to establish or enhance your IT department? Rely on our
                guidance. We assist in structuring, recruiting top talent,
                defining processes, and implementing best practices, ensuring
                your IT department operates at peak efficiency.
              </Paragraph>
            </Yus>
            <Yus>
              <YusTitle>System Performance Analysis</YusTitle>
              <Paragraph>
                We conduct comprehensive system performance analyses to identify
                bottlenecks, optimize configurations, and enhance overall system
                efficiency. Our in-depth assessments lead to actionable insights
                for improving your IT infrastructure's performance.
              </Paragraph>
            </Yus>
            <Yus>
              <YusTitle>IT Cost Optimizations</YusTitle>
              <Paragraph>
                Efficiently manage IT costs without compromising quality. Our
                consultants identify cost-saving opportunities, recommend
                optimized solutions, negotiate vendor contracts, and streamline
                operations to ensure maximum value from your IT investments.
              </Paragraph>
            </Yus>
          </ServiceContainer>
        </Container>
      </Wrapper>
      <ContactComponent />
    </>
  );
};

export default Consulting;
