import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Banner from "../components/Banner";
import BGI from "../assets/img/banners/aboutbanner@2x.png";
import MidIMG from "../assets/background/partners.png";
import aws from "../assets/img/partner-logo/aws2x.png";
import gcp from "../assets/img/partner-logo/GCP2x.png";
import azure from "../assets/img/partner-logo/MS2x.png";
import vmv from "../assets/img/partner-logo/VMware.jpg";
import ng from "../assets/img/partner-logo/NG2x.png";
import rh from "../assets/img/partner-logo/RH2x.png";
import lp from "../assets/img/partner-logo/Silver Partnership Badge.jpg";
import utho from "../assets/img/partner-logo/utho.png";
import MissionVission from "../components/MissionVision";
import WhatWeDo from "../components/WhatWeDo";

const Wrapper = styled.div`
  width: 100%;
  justify-content: center;
`;
const Container = styled.div`
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;
const Paragraph = styled.p`
  /* font: normal normal medium 50px/40px Manrope; */
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  font-family: Manrope;
  text-align: justify;
  color: #000000;
  line-height: 200%;
`;
const MidImage = styled.img`
  width: 100%;
  margin-bottom: 30px;
`;
const TitleBG = styled.div`
  height: 60px;
  background: #7b1845 0% 0% no-repeat padding-box;
  border-radius: 3px;
  margin-top: 50px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Title = styled.h3`
  color: var(--e-global-color-f57c355);
  font-family: "Poppins", Sans-serif;
  font-size: 35px;
  font-weight: 700;
  text-transform: uppercase;
`;

const PartnerBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const LogoContent = styled.div`
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Logo = styled.img`
  padding: 10px;
  width: 250px;
`;

const Partners = () => {
  return (
    <>
      <Helmet>
        <title>Best Practices for a Successful Cloud Migration Project</title>
        <meta
          name="description"
          content="We specialize in Cloud Architecture, Cloud Services, and System Integration for migration experience. We support from planning & execution to post-migration."
        />
        <meta
          name="keywords"
          content="Cloud Migration Project, Cloud Architecture, Cloud Services, cloud migration, System Integration"
        />
      </Helmet>
      <Banner image={MidIMG} title={"Our Partners"} subtitle={""} />
      <Wrapper style={{ marginTop: "30px" }}>
        <Container>
          <Title>Our Partners</Title>

          <Paragraph style={{ textAlign: "center" }}>
            We have partnered with leading industry experts in the public cloud
            and private cloud.
          </Paragraph>
          <PartnerBlock>
            <LogoContent>
              <Logo src={aws} />
            </LogoContent>
            <a
              href="https://console.utho.com/signup?partnerid=197680"
              target="_blank"
              rel="noreferrer"
            >
              <LogoContent>
                <Logo
                  src="https://utho.com/images/logo_black.png"
                  alt="Utho Logo"
                />
              </LogoContent>
            </a>
            <LogoContent>
              <Logo src={gcp} />
            </LogoContent>
            <LogoContent>
              <Logo src={azure} />
            </LogoContent>
            <LogoContent>
              <Logo src={rh} />
            </LogoContent>
            <LogoContent>
              <Logo src={vmv} />
            </LogoContent>
            <LogoContent>
              <Logo src={ng} style={{ width: "200px" }} />
            </LogoContent>
            <LogoContent>
              <Logo src={lp} style={{ width: "200px" }} />
            </LogoContent>
          </PartnerBlock>
        </Container>
      </Wrapper>
    </>
  );
};

export default Partners;
