import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Banner from "../components/Banner";
import BGI from "../assets/background/aboutus.png";
import MidIMG from "../assets/img/banners/aboutbanner@2x.png";
import MissionVision from "../components/MissionVision";
import WhatWeDo from "../components/WhatWeDo";

const Wrapper = styled.div`
  width: 100%;
  justify-content: center;
`;
const Container = styled.div`
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;
const Paragraph = styled.p`
  /* font: normal normal medium 50px/40px Manrope; */
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  font-family: Manrope;
  text-align: justify;
  color: #000000;
  line-height: 200%;
`;
const MidImage = styled.img`
  width: 100%;
  margin-bottom: 30px;
`;
const TitleBG = styled.div`
  height: 60px;
  background: #7b1845 0% 0% no-repeat padding-box;
  border-radius: 3px;
  margin-top: 50px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Title = styled.h3`
  color: var(--e-global-color-f57c355);
  font-family: "Poppins", Sans-serif;
  font-size: 35px;
  font-weight: 700;
  text-transform: uppercase;
`;

const Partners = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const LogoContent = styled.div`
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Logo = styled.img`
  padding: 10px;
  width: 250px;
`;

const About = () => {
  return (
    <>
      <Helmet>
        <title>Best Practices for a Successful Cloud Migration Project</title>
        <meta
          name="description"
          content="We specialize in Cloud Architecture, Cloud Services, and System Integration for migration experience. We support from planning & execution to post-migration."
        />
        <meta
          name="keywords"
          content="Cloud Migration Project, Cloud Architecture, Cloud Services, cloud migration, System Integration"
        />
      </Helmet>
      <Banner image={BGI} title={"About Us"} subtitle={""} />
      <Wrapper style={{ marginTop: "30px" }}>
        <Container>
          <Paragraph>
            Cylogics founded on mid of 2018, where it started with a vision of
            Cloud Consultancy Services, which were limited to Cloud Design /
            Architecture, and Cloud Migration Project Management. During the
            pandemic, it has further enhanced to Managed Cloud Services also,
            since the demand from its customers currently we are managing more
            than 100 Servers and 60 VPCs' s scattered on Google Cloud Platform
            and Amazon Web Services. We can build a complete CI/CD platform on
            GCP. Our Clients include the verticals from fintech, co-operative
            societies, retail, supply chain etc.
          </Paragraph>
          <Paragraph>
            We cover a wide range of enterprise solutions such as server
            clustering, Business Continuity, Disaster Recovery or Cloud
            Migration, Managed Cloud Services, and Cloud Security. We focus on
            SMEs, MNCs, and corporate and government sectors. Our policy is to
            provide the best possible solutions and support to our valued
            customers. We have assisted numerous corporations to increase
            business competitiveness by revolutionizing and adopting today’s
            innovative technology. Our primary focus is on infrastructure and
            cloud services based on GCP / AWS / AZURE.
          </Paragraph>
        </Container>
        {/* <MidImage src={MidIMG} /> */}

        <Container>
          <Paragraph>
            At Cylogics, our commitment extends beyond delivering top-notch and
            timely security services; we prioritize ensuring that our customers
            reap substantial benefits from the solutions we offer. Consider us
            your dependable support system, enhancing technological
            implementations for optimal security outcomes. As a value-added
            System Integrator, we are dedicated to furnishing comprehensive
            infrastructure and network solutions with a core focus on security
            for our clientele.
          </Paragraph>
        </Container>
        <Container>
          <MissionVision />

          <Title>Digital Transformation</Title>

          <Paragraph>
            "In an era defined by rapid technological advancements, embracing
            digital transformation is pivotal for business success. At CYLOGICS,
            we serve as the gateway to innovation, harnessing agile and
            pioneering technologies such as blockchain, Data Science, and Cloud
            to revolutionize your operations while placing a strong emphasis on
            security.
          </Paragraph>
          <Paragraph>
            Our approach goes beyond adopting the latest trends; it involves a
            deep understanding of your unique needs, translating them into
            tangible, future-ready solutions. Delving into your business
            ecosystem, we grasp the evolving demands of your customers and the
            intricacies of your operations.
          </Paragraph>
          <Paragraph>
            Through the power of blockchain, we not only secure but also
            streamline your transactions, ensuring transparency and trust in
            every interaction. Our expertise in Data Science unlocks hidden
            potential within your data, providing actionable insights that drive
            informed decisions and strategic growth while prioritizing data
            security at every step.
          </Paragraph>
          <Paragraph>
            Utilizing Cloud technology, we pave the way for scalable, flexible,
            and cost-efficient infrastructures, empowering your business to
            adapt swiftly to changing market landscapes while maintaining
            stringent security measures.
          </Paragraph>
          <Paragraph>
            Each solution we craft is more than just a product; it's a tailored,
            transparent, and scalable enterprise solution. It's a manifestation
            of our commitment to addressing your specific needs while aligning
            seamlessly with your business environment and strategic goals, all
            while prioritizing the security and integrity of your systems and
            data.
          </Paragraph>
          <Paragraph>
            By partnering with us, you're not just embracing change; you're
            embracing innovation and fortifying your business for the future.
            Together, we'll navigate the digital transformation landscape,
            ensuring your business remains at the forefront of innovation, ready
            to seize new opportunities and confront evolving challenges with a
            robust and secure technological foundation."
          </Paragraph>
          <WhatWeDo />
        </Container>
      </Wrapper>
    </>
  );
};

export default About;
