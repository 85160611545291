import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import CareersComp from "../components/CareersComp";
import BGI from "../assets/background/lock.png";
import Banner from "../components/Banner";

const Wrapper = styled.div`
  width: 100%;
  justify-content: center;
`;

const Container = styled.div`
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
`;

const Careers = () => {
  return (
    <>
      <Helmet>
        <title>Careers - Cyber Engineering</title>
        <meta
          name="description"
          content="We specialize in Cloud Architecture, Cloud Services, and System Integration for migration experience. We support from planning & execution to post-migration."
        />
        <meta
          name="keywords"
          content="Cloud Migration Project, Cloud Architecture, Cloud Services, cloud migration, System Integration"
        />
      </Helmet>
      <Wrapper style={{ marginTop: "80px" }}>
        <Banner
          image={BGI}
          title={"We are Hiring !! Come Join Us!!"}
          subtitle={"Send CV to :  info@cylogics.com"}
        />

        <Container>
          <CareersComp />
        </Container>
      </Wrapper>
    </>
  );
};

export default Careers;
