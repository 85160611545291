import React from 'react';

const jay = () => {
  return (
    <>
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Jayan Varma | Founder - Cylogics</title>
      <link href="assets/css/style.css" type="text/css" rel="stylesheet" />
      <link rel="icon" href="assets/logo.png" type="image/gif" sizes="16x16" />
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta1/dist/css/bootstrap.min.css"
      />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      />
      <div className="row d-flex justify-content-center bg-contact-card ">
        <div className="col-md-12 ">
          <div className="p-4">
            <div className="text-center">
              {' '}
              <img
                src="assets/img/profilepic.png"
                width={100}
                className="rounded-circle"
                alt=""
              />{' '}
            </div>
            <div className="text-center mt-3">
              <h1 className=" mt-2">Jay</h1>
              <br />
              <span className="bg-warning p-1 px-4 rounded text-white align-middle">
                <strong>Founder &amp; MD</strong>
              </span>
              {/* <div className="p-1">
                  <img src="assets/logo.png" width={200} alt="Cylogics" />
                </div> */}
              <ul className="social-list">
                <li>
                  <a href="https://www.facebook.com/JayanThrissur/">
                    <i
                      className="fa fa-facebook fa-2x"
                      style={{color: 'blue'}}
                    />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/jayantsr/">
                    <i
                      className="fa fa-instagram fa-2x"
                      style={{color: '#8a3ab9'}}
                    />
                  </a>
                </li>
                <li>
                  <a href="https://in.linkedin.com/in/jayantsr">
                    <i
                      className="fa fa-linkedin fa-2x"
                      style={{color: 'blue'}}
                    />
                  </a>
                </li>
                <li>
                  <a href="mailto:jayan.varma@cylogics.com">
                    <i
                      className="fa fa-envelope fa-2x"
                      style={{color: 'blue'}}
                    />
                  </a>
                </li>
              </ul>
              <ul>
                <li>
                  <a href="assets/jvc.vcf">
                    <button className="btn btn-secondary">
                      Add to Conatacts <i className="fa fa-arrow-down" />
                    </button>
                  </a>
                </li>
              </ul>
              <ul className="list-group">
                <li className="list-group-item">
                  <h6>
                    <i className="fa fa-phone"> +91 9495 11 4626</i>
                  </h6>
                </li>
                <li className="list-group-item">
                  <a href="https://api.whatsapp.com/send?phone=919495114626&text=Hi!%20Boche%20?">
                    <i className="fa fa-whatsapp" style={{color: 'darkgreen'}}>
                      {' '}
                      +91 9495 11 4626
                    </i>
                  </a>
                </li>
                <li className="list-group-item">
                  <a href="https://cylogics.com/">
                    <i className="fa fa-globe"> </i>  https://cylogics.com/
                  </a>
                </li>
                <li className="list-group-item">
                  <img src="assets/LogoN.png" width={200} alt="Cylogics" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default jay;
