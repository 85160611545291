import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PageBorder = styled.div`
  margin-top: 110px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  border: 4px double #000;
  border-radius: 10px;
`;
const Container = styled.div`
  margin-top: 20px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  /* font: normal normal medium 50px/40px Manrope; */
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  font-family: Manrope;
  padding: 10px;
  color: #000000;
  line-height: 200%;
  text-align: justify;
`;

const Title = styled.h3`
  color: var(--e-global-color-f57c355);
  font-family: 'Poppins', Sans-serif;
  font-size: 35px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
`;

const PaaS = () => {
  return (
    <Wrapper>
      <PageBorder>
        <Container>
          <Title>What is PaaS (Platform as a Service)?</Title>
          <Paragraph>
            PaaS stands for Platform as a Service. It is a cloud computing model
            that provides a platform allowing developers to build, deploy, and
            manage applications without dealing with the underlying
            infrastructure.
          </Paragraph>
          <Paragraph>
            In a PaaS environment, developers have access to various tools,
            programming languages, libraries, and pre-built components to
            develop, test, and deploy their applications. This platform
            typically includes features such as development tools, application
            hosting, database management systems, middleware, and more.
          </Paragraph>
          <Paragraph>
            PaaS abstracts much of the complexity involved in setting up and
            managing the underlying infrastructure, including servers, storage,
            networking, and operating systems. This allows developers to focus
            primarily on writing code, accelerating the development process, and
            reducing the time needed for deployment.
          </Paragraph>
          <Paragraph>
            By leveraging PaaS, developers can create scalable and flexible
            applications without the need to manage the hardware and software
            infrastructure, thus enabling faster innovation, increased
            productivity, and reduced operational overhead for organizations.
          </Paragraph>
        </Container>
      </PageBorder>
    </Wrapper>
  );
};

export default PaaS;
