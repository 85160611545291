import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PageBorder = styled.div`
  margin-top: 110px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  border: 4px double #000;
  border-radius: 10px;
`;
const Container = styled.div`
  margin-top: 20px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  /* font: normal normal medium 50px/40px Manrope; */
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  font-family: Manrope;
  padding: 10px;
  color: #000000;
  line-height: 200%;
  text-align: justify;
`;

const Title = styled.h3`
  color: var(--e-global-color-f57c355);
  font-family: 'Poppins', Sans-serif;
  font-size: 35px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
`;

const Subtitle = styled.h2`
  color: var(--e-global-color-f57c355);
  font-family: 'Poppins', Sans-serif;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
`;

const whatsCloud = () => {
  return (
    <>
      <Wrapper>
        <PageBorder>
          <Container>
            <Title>Understanding the Cloud !!!</Title>
            <Paragraph>
              The Cloud represents a paradigm shift in how we access, store,
              process, and manage data and applications. Unlike traditional
              computing, where data and software were stored and executed on
              local devices or servers, the Cloud offers a distributed network
              of remote servers hosted in data centers worldwide. These servers
              are interconnected and operate collaboratively to provide a vast
              array of services and resources to users and businesses across the
              globe.
            </Paragraph>
            <Subtitle>Essential Characteristics</Subtitle>
            <Paragraph>
              One of the primary characteristics of the Cloud is its
              accessibility. Users can access resources and services through the
              internet from anywhere, using various devices such as computers,
              smartphones, or tablets. This accessibility is coupled with
              scalability, allowing users to quickly and dynamically adjust
              their resources based on demand. Whether it's increasing storage
              capacity or expanding computing power, the Cloud offers the
              flexibility to scale up or down without the need for significant
              infrastructure investments.
            </Paragraph>
            <Subtitle>Types of Cloud Services</Subtitle>
            <p>The Cloud offers different service models:</p>
            <ol>
              <li>
                {' '}
                Infrastructure as a Service (IaaS): Provides virtualized
                computing resources like virtual machines, storage, and
                networking. Users have control over the operating systems and
                applications they want to run.
              </li>

              <li>
                Platform as a Service (PaaS): Offers a platform allowing
                developers to build, deploy, and manage applications without
                dealing with the underlying infrastructure. It provides tools
                and services needed for application development.
              </li>
              <li>
                Software as a Service (SaaS): Delivers software applications
                over the internet on a subscription basis. Users access the
                software through a web browser without worrying about
                installation, maintenance, or upgrades.
              </li>
            </ol>
            <Subtitle>Benefits of Cloud Computing</Subtitle>
            <Paragraph>
              The Cloud brings numerous benefits. Cost-effectiveness is a
              significant advantage as it reduces the need for upfront
              investments in hardware and allows for pay-as-you-go pricing
              models, where users only pay for the resources they consume.
              Additionally, the Cloud enhances collaboration by enabling
              real-time sharing and editing of documents among teams from
              different locations. Moreover, it fosters innovation by providing
              access to cutting-edge technologies like AI, Machine Learning, and
              Big Data analytics, which were previously only available to large
              enterprises with substantial resources.
            </Paragraph>
            <Subtitle>Security and Challenges</Subtitle>
            <Paragraph>
              While the Cloud offers robust security measures, including data
              encryption, access controls, and regular updates to protect
              against cyber threats, security concerns persist. Issues related
              to data privacy, compliance, and the risk of unauthorized access
              or data breaches require continuous attention and implementation
              of robust security measures by both service providers and users.
            </Paragraph>
            <Subtitle>Conclusion</Subtitle>
            <Paragraph>
              In essence, the Cloud represents a fundamental shift in how
              businesses and individuals leverage technology. Its scalability,
              flexibility, accessibility, and diverse range of services empower
              organizations to innovate, scale, and remain competitive in a
              rapidly evolving digital landscape. As the technology continues to
              evolve, the Cloud remains a pivotal force in shaping the future of
              computing and digital transformation across industries.
            </Paragraph>
          </Container>
        </PageBorder>
      </Wrapper>
    </>
  );
};

export default whatsCloud;
